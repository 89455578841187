import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import { useTitle } from "../../common/utils";
import SubDetailLCT from "../../components/ManageAllSubscriptions/DetailPages/SubDetailLCT";
import SubDetailPremiumCare from "../../components/ManageAllSubscriptions/DetailPages/SubDetailPremiumCare";
import FA from "../../containers/fa";
import MainDashboard from "../../partials/Main";
import withAPI from "../../services/api";

const SubscriptionInfoContainer = ({ api, match }) => {
  useTitle("Subscription Info");
  let history = useHistory();

  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadSubscriptions();
  }, []);

  const loadSubscriptions = () => {
    let queryParams = {
      sub_id: match.params.subId,
      sub_type: match.params.subscriptionType,
      detail_view: true,
      org_id: match.params.orgId,
    };

    queryParams = Object.entries(queryParams).filter(([k, v]) => v);

    console.log(queryParams);

    if (match.params.subscriptionType === "premiumcare") {
      api
        .fetchSubscriptionInfo(match.params.subId)
        .then((objects) => {
          setSubscriptionInfo(objects.data);
          console.log("SubscriptionInfo:",objects.data);
        })
        .catch((error) => {
          console.log(error);
          setError(true);
        });
    } else {
      api
        .fetchSubscriptionsTypesInfo(queryParams)
        .then((objects) => {
          setSubscriptionInfo(objects.data);
        })
        .catch((error) => {
          console.log(error);
          setError(true);
        });
    }
  };

  return (
    <div>
      <MainDashboard>
        <div className='common_heading d-flex justify-content-between align-items-center'>
          <h4>Subscription Details</h4>
          &nbsp;
          <button
            className='btn btn-sm btn-primary'
            onClick={() => history.goBack()}
          >
            {" "}
            Back{" "}
          </button>
        </div>

        <div className='common_dashboard_bg'>
          <div className='row'>
            <div className='col-12'>
              <div className='row mb-10'></div>
              {error && (
                <div className='alert alert-danger' role='alert'>
                  {error}
                </div>
              )}
              {!subscriptionInfo && !error && (
                <div>
                  <h4>
                    <FA color='gray' icon='spinner' spin /> Loading Subscription
                    info...
                  </h4>
                </div>
              )}
              {subscriptionInfo && match.params.subscriptionType === "lct" && (
                <SubDetailLCT
                  subData={subscriptionInfo}
                  refreshSubs={loadSubscriptions}
                />
              )}

              {subscriptionInfo &&
                match.params.subscriptionType === "premiumcare" && (
                  <SubDetailPremiumCare
                    subData={subscriptionInfo}
                    refreshSubs={loadSubscriptions}
                    subscriptionType='premiumcare'
                  />
                )}
            </div>
          </div>
        </div>
      </MainDashboard>
    </div>
  );
};

export default compose(withAPI)(SubscriptionInfoContainer);
