import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import FA from "../../../containers/fa";
import { setHasRenewalsWithoutRobots } from "../../../redux/actions";
import withAPI from "../../../services/api";
import RobotRow from "../..//ManageAllRobots/RobotRow.js";
import InfoPopup from "../../UtilComps/InfoPopup";
import PaginationBar from "../../UtilComps/PaginationBar.js";
import "./SubRobot.scss";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
});

const RobotSection = ({
  api,
  user,
  objInfo,
  refreshParent,
  parentType,
  variant,
  setShowParent,
  selectionCountLimit,
  dispatch,
  showSelectAllButton,
}) => {
  const doPagination = variant !== "addRobotPopup";
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  // robot table
  const [robots, setRobots] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [paginationShouldSpin, setPaginationShouldSpin] = useState(false);
  const [pageSize, setPageSize] = useState(null);
  const [sortType, setSortType] = useState("recent_lesson_count");
  const [sortAsc, setSortAsc] = useState(false);
  const [sortTouched, setSortTouched] = useState(false);
  const [infoText, setInfoText] = useState(null);

  // for variant addRobotPopup
  const [selectedRobots, setSelectedRobots] = useState(new Set());
  const [savingChanges, setSavingChanges] = useState(false);
  const [refreshingRobots, setRefreshingRobots] = useState(false);

  const fetchRobots = (pageNum) => {
    let queryParams = {
      sort: (sortAsc ? "" : "-") + sortType,
      page_size: pageSize,
    };

    // which robots
    if (parentType === "subscription") {
      queryParams["subscription"] = objInfo.id;
    } else if (parentType === "renewal") {
      queryParams["renewal"] = objInfo.id;
    }

    if (variant === "addRobotPopup") {
      queryParams["add_robot_view"] = true;
      queryParams["show_all"] = true;
    } else if (pageNum) {
      queryParams["page"] = pageNum;
    }

    // skip nulls,  empty strings, false values
    queryParams = Object.entries(queryParams).filter(([k, v]) => v);

    setPaginationShouldSpin(true);

    api
      .fetchAllVisibleRobots(queryParams)
      .then((objects) => {
        setRobots(objects.results);
        delete objects["results"];
        delete objects["stats"];
        setPaginationInfo(objects);
        setPaginationShouldSpin(false);
        if (refreshingRobots) {
          setRefreshingRobots(false);
          setSuccess(`Robot(s) added to ${parentType}!`);
        }
      })
      .catch((e) => {
        setRefreshingRobots(false);
        setError("An error occurred. Please try again later.");
      });
  };

  useEffect(() => {
    if (sortTouched === true) {
      fetchRobots(null);
    }
  }, [sortType, sortAsc]);

  useEffect(() => {
    if (objInfo) {
      fetchRobots();
    }
  }, [objInfo]);

  const handleColumnHeaderClick = (column) => {
    setSortTouched(true);
    if (sortType === column) {
      setSortAsc(!sortAsc);
    } else {
      setSortType(column);
      setSortAsc(false);
    }
  };

  const getHeaderClasses = (column) => {
    let classes = "sortable";
    if (sortType === column) {
      if (sortAsc === false) {
        classes += " headerSortDown";
      } else {
        classes += " headerSortUp";
      }
    } else {
      classes += " headerSort";
    }
    return classes;
  };

  const handleSuccessRemoval = () => {
    refreshParent();
    if (parentType === "renewal") {
      callSetHasRenewalsWithoutRobots(objInfo.new_subscription.organization);
    } else if (parentType === "subscription") {
      callSetHasRenewalsWithoutRobots(objInfo.organization);
    }
  };

  useEffect(() => {
    console.log(selectedRobots);
  }, [selectedRobots]);

  const handleCheckboxChange = (serial) => {
    const _tmp = new Set(selectedRobots);
    if (_tmp.has(serial)) {
      _tmp.delete(serial);
    } else {
      _tmp.add(serial);
    }
    setSelectedRobots(_tmp);
  };

  /**
   * selectAllRobots gets how many selections are available and starts auto
   * selecting robots on the list, from top to bottom, until all the slots have
   * run out.
   * @returns null
   */
  const selectAllRobots = () => {
    let slotsLeft = selectionCountLimit - selectedRobots.size;
    if (slotsLeft <= 0) return;

    const selected_robots_tmp = new Set(selectedRobots);
    for (let i = 0; i < robots.length; i++) {
      if (slotsLeft <= 0) {
        // we have selected the max number of robots
        break;
      }
      if (!selected_robots_tmp.has(robots[i].serial)) {
        selected_robots_tmp.add(robots[i].serial);
        slotsLeft--;
      }
    }

    setSelectedRobots(selected_robots_tmp);
  };

  const deselectAllRobots = () => {
    setSelectedRobots(new Set());
  };

  const handleAddRobotsToRenewal = () => {
    const patchData = {
      robots: [...selectedRobots],
      action: "add",
    };
    api
      .patchRenewalInfo(objInfo.id, patchData)
      .then((r) => {
        setSelectedRobots(new Set());
        setSavingChanges(false);
        setRefreshingRobots(true);
        refreshParent();

        callSetHasRenewalsWithoutRobots(objInfo.new_subscription.organization);
      })
      .catch((e) => {
        console.log("Error adding robot to renewal...");
        console.log(e);
        if (e.message && e.message.includes("Too many robots")) {
          setError(
            "Too many robots have been selected. Please refresh the page and try again ..."
          );
        } else {
          setError("An error occurred. Please try again later.");
        }
        setSavingChanges(false);
      });
  };

  const callSetHasRenewalsWithoutRobots = (orgId) => {
    api.checkForRenewalsWithoutRobots(orgId).then((res) => {
      dispatch(setHasRenewalsWithoutRobots(res.has_renewals_without_robots));
    });
  };

  const handleAddRobotsToSubscriptions = () => {
    const postData = {
      object_list: [...selectedRobots],
      subscription_id: objInfo.id,
      object_type: "robot",
      action: "add",
    };

    api
      .manageSubscriptionObjects(postData)
      .then((r) => {
        setSelectedRobots(new Set());
        setSavingChanges(false);
        setRefreshingRobots(true);
        refreshParent();

        callSetHasRenewalsWithoutRobots(objInfo.organization);
      })
      .catch((e) => {
        console.log("Error adding robot to subscription...");
        console.log(e);
        if (e.message && e.message.includes("Too many robots")) {
          setError(
            "Too many robots have been selected. Please refresh the page and try again ..."
          );
        } else {
          setError("An error occurred. Please try again later.");
        }
        setSavingChanges(false);
      });
  };

  function handleAddRobots() {
    setSuccess(null);
    setError(null);
    setSavingChanges(true);

    if (parentType === "renewal") {
      handleAddRobotsToRenewal();
    } else if (parentType === "subscription") {
      handleAddRobotsToSubscriptions();
    }
  }

  return (
    <div id='robot_search_results_container' className='visible-scrollbar'>
      {infoText && (
        <InfoPopup popupInfo={infoText} setShowPopupInfo={setInfoText} />
      )}

      {error && (
        <div className='alert alert-danger' role='alert'>
          {error}
        </div>
      )}

      {success && (
        <div className='alert alert-success' role='alert'>
          {success}
        </div>
      )}

      {(savingChanges || refreshingRobots) && (
        <div className='alert alert-info' role='alert'>
          <FA icon='spinner' spin className='mr-2' />
          {savingChanges
            ? "Saving changes ..."
            : `Refreshing robots and ${parentType} info ...`}
        </div>
      )}

      {doPagination && (
        <PaginationBar
          paginationInfo={paginationInfo}
          fetchPageFunc={fetchRobots}
          loading={paginationShouldSpin}
          setPageSize={setPageSize}
          pageSize={pageSize}
        />
      )}

      <div
        className='card progress-linear'
        style={{ marginBottom: "0rem", minHeight: "200px" }}
      >
        <span className='py-3 mx-2 d-flex justify-content-between'>
          <strong>
            {" "}
            <h5>Robots</h5>
          </strong>
          {showSelectAllButton && (
            <div>
              <button
                className='btn btn-md btn-primary mr-2'
                id='select_all_robots_btn'
                onClick={() => {
                  selectAllRobots();
                }}
              >
                Select Max Robots
              </button>

              <button
                className='btn btn-md btn-primary mr-2'
                id='deselect_all_robots_btn'
                onClick={() => {
                  deselectAllRobots();
                }}
              >
                Deselect All
              </button>
            </div>
          )}
        </span>

        <div className='visible-scrollbar' style={{ overflowX: "auto" }}>
          <div id='robots_table_row_subscription_hrs'>
            <div>
              <p></p>
            </div>

            <div
              className='th_sortable_container robots_table_hr'
              onClick={() => {
                handleColumnHeaderClick("wifi");
              }}
            >
              <p className={getHeaderClasses("wifi")}>Wifi name</p>
            </div>

            <div
              className='th_sortable_container robots_table_hr'
              onClick={() => {
                handleColumnHeaderClick("code_ver");
              }}
            >
              <p className={getHeaderClasses("code_ver")}>Version</p>
            </div>

            <div
              className='th_sortable_container robots_table_hr'
              onClick={() => {
                handleColumnHeaderClick("end_date");
              }}
            >
              <p className={getHeaderClasses("end_date")}>Expiration Date</p>
            </div>

            <div
              className='th_sortable_container robots_table_hr'
              onClick={() => {
                handleColumnHeaderClick("last_synced_on");
              }}
            >
              <p className={getHeaderClasses("last_synced_on")}>
                Last Online Check-in
              </p>
            </div>

            <div>
              <p
                style={{
                  display: "flex",
                  width: "min-content",
                  alignItems: "center",
                  marginLeft: "14px",
                }}
              >
                Active Learners
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    position: "relative",
                    padding: "2px",
                  }}
                  onClick={() => {
                    setInfoText({
                      header: "Active Learners",
                      text: "This represents the number of learners that took lessons in the past 30 days.",
                    });
                  }}
                >
                  <FA
                    icon='info-circle'
                    className='info-circle robots_table_hr'
                    title='Number of learners that took lessons in the past 30 days.'
                  />
                </button>
              </p>
            </div>

            <div
              className='th_sortable_container'
              onClick={() => {
                handleColumnHeaderClick("recent_lesson_count");
              }}
            >
              <p
                className={getHeaderClasses("recent_lesson_count")}
                style={{ width: "min-content" }}
              >
                Recent Lessons
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    position: "relative",
                    padding: "2px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setInfoText({
                      header: "Recent Lessons",
                      text: "This represents the number of lessons taken in the past 30 days.",
                    });
                  }}
                >
                  <FA
                    icon='info-circle'
                    className='info-circle robots_table_hr'
                    title='Number of lessons taken in the past 30 days.'
                  />
                </button>
              </p>
            </div>

            <div>
              <p>School</p>
            </div>

            <div className='robots_table_hr'>
              <div className='d-flex justify-content-between'>
                <p>Teacher Info</p>
              </div>
            </div>

            <div className='robots_table_hr'>
              <div className='d-flex justify-content-between'>
                <p className='mr-2'>
                  {variant !== "addRobotPopup" ? "Actions" : "Select"}
                </p>
              </div>
            </div>
          </div>
          <div>
            {paginationInfo &&
              robots.map((rbt, ind) => (
                <RobotRow
                  ind={
                    doPagination ? paginationInfo.start_index + ind : ind + 1
                  }
                  key={rbt.serial}
                  rbt={rbt}
                  showOnlineNow={false}
                  usertype={user.usertype}
                  variant={`${parentType}_${variant}`}
                  subOrRenewalId={objInfo.id}
                  handleSuccessRemoval={handleSuccessRemoval}
                  checkboxAddDisabled={
                    selectedRobots.size >= selectionCountLimit
                  }
                  checkboxChecked={selectedRobots.has(rbt.serial)}
                  handleCheckboxChange={handleCheckboxChange}
                />
              ))}
            {robots.length === 0 && paginationShouldSpin && (
              <div className='py-3 w-100'>
                <p
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FA color='gray' icon='spinner' spin className='mr-2' />
                  Loading robots ...
                </p>
              </div>
            )}
          </div>
        </div>
        {robots.length === 0 && !paginationShouldSpin && (
          <div className='p-3' style={{ width: "100%" }}>
            {variant === "addRobotPopup"
              ? "No robots available."
              : "No robots under this subscription."}
          </div>
        )}
      </div>

      {doPagination && (
        <PaginationBar
          paginationInfo={paginationInfo}
          fetchPageFunc={fetchRobots}
          loading={paginationShouldSpin}
          pageSize={pageSize}
        />
      )}

      {variant === "addRobotPopup" && (
        <div className='mt-3 d-flex justify-content-center text-center'>
          <div>
            <p className='mb-1'>{selectedRobots.size} robots selected.</p>
            <div>
              <button
                onClick={() => {
                  handleAddRobots();
                }}
                className='btn btn-md btn-primary mr-2'
                disabled={
                  selectedRobots.size === 0 ||
                  savingChanges ||
                  selectionCountLimit <= 0
                }
              >
                Save Selections
              </button>
              <button
                onClick={() => {
                  setShowParent(false);
                }}
                disabled={savingChanges}
                className='btn btn-md btn-danger'
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(RobotSection);
