import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { useDebounce } from 'use-debounce';


import withAPI from '../../../services/api';

import config from '../../../common/config';
import FAW from '../../../containers/faw';
import FA from '../../../containers/fa';
import CreateSuperAdminSchoolModal from './CreateSuperAdminSchoolModal';
import SuperAdminSchoolDeleteModal from './SuperAdminSchoolDeleteModal';

const OrgSuperAdminSchoolsTable = ({api, orgId, canCollapse, orgLevel, schoolRefreshFromContacts}) => {
  const [ready, setReady] = useState(false);
  const [schools, setSchools] = useState([]);
  const [spin, setSpin] = useState(true);
  const [error, setError] = useState(null);

  const [filterString, setFilterString] = useState("");
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [debouncedFilterString] = useDebounce(filterString, 1000);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [refreshSchoolList, setRefreshSchoolList] = useState(false);

  useEffect(() => {
    console.log("Asking for schools in org:", orgId);
    api.fetchSuperAdminSchools(orgId).then((r) => {
      setSchools(r);
      setReady(true);
      setSpin(false);
    });
  }, [refreshSchoolList, schoolRefreshFromContacts]);

  useEffect(() => {
    console.log("filterString is Now... ", filterString);
    if (debouncedFilterString === "") {
      setFilteredSchools(schools);
    } else {
      const results = schools.filter((r) =>
        JSON.stringify(r).toLowerCase().includes(debouncedFilterString)
      );
      setFilteredSchools(results);
      console.log("filteredSchools is now: ", filteredSchools);
    }
  }, [debouncedFilterString, schools]);

  const triggerRefresh = () => {
    setRefreshSchoolList(!refreshSchoolList);
  };

  const [schoolToDelete, setSchoolToDelete] = useState(null);
  const [schoolToEdit, setSchoolToEdit] = useState(null);
  const [saveSpin, setSaveSpin] = useState(false);
  const [newSchoolName, setNewSchoolName] = useState("");

  const handlePatch = () => {
    if (newSchoolName) {
      setSaveSpin(true);
      let data = {name: newSchoolName};
      api
        .patchSuperAdminSchool(schoolToEdit, data)
        .then((resp) => {
          setSaveSpin(false);
          setSchoolToEdit(null);
          triggerRefresh();
        })
        .catch((err) => {
          setSaveSpin(false);
          console.log("API error'd: ", err);
        });
    }
  };

  return (
    <div className='common_border'>
      <div className='common_heading d-flex justify-content-between align-items-center'
        style={{cursor: "pointer"}}
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        <span>
          <h4>
            {canCollapse && (
              <span
                className='mr-2'
              >
                {collapse ? (
                  <FA color='gray' icon='chevron-right' />
                ) : (
                  <FA color='gray' icon='chevron-down' />
                )}
              </span>
            )}
            Schools/Buyer Labels
          </h4>
        </span>
      </div>

      {showCreateModal && (
        <CreateSuperAdminSchoolModal
          orgId={orgId}
          shouldShowFunc={setShowCreateModal}
          refreshList={triggerRefresh}
        />
      )}

      {schoolToDelete && (
        <SuperAdminSchoolDeleteModal
          superAdminSchoolId={schoolToDelete}
          refreshList={triggerRefresh}
          shouldShowFunc={setSchoolToDelete}
        />
      )}

      {!collapse && (
        <div className='common_dashboard_bg'>
          <div className='row'>
            <div className='col-12'>
              {error && (
                <div className='alert alert-danger' role='alert'>
                  {error}
                </div>
              )}
              {spin && (
                <div>
                  <h4>
                    <FA color='gray' icon='spinner' spin /> Loading Schools...
                  </h4>
                </div>
              )}

              {!spin && (
                <div>
                  <div className='d-flex' style={{marginBottom: '5px'}}>
                    <div className='d-flex justify-content-end'>
                      <label className='pt-2 mr-2'>
                        <FA icon='search' />
                      </label>
                      &nbsp;
                      <input
                        placeholder='Search ...'
                        className='form-control input-underline'
                        value={filterString}
                        onChange={(e) =>
                          setFilterString(e.target.value.toLowerCase())
                        }
                      />
                    </div>
                    {orgLevel === config.ORG_LEVEL_DISTRICT ? (
                      <button
                        className='btn btn-primary ml-4'
                        onClick={() => setShowCreateModal(true)}
                      >
                        Add Buyer Label
                      </button>
                    ) : (
                      <button className='btn btn-secondary ml-4' disabled>
                        School-level Org
                      </button>
                    )}
                  </div>
                  <div className='card'>
                    <div
                      className='progress-linear school_listing manage_learner_table'
                      role='progressbar'
                    >
                      <table className='table table-striped'>
                        <thead>
                          <tr style={{textAlign: 'left'}}>
                            <th>Buyer Label</th>
                            <th style={{width: "100px"}}>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredSchools &&
                            filteredSchools.map((school) => (
                              <tr key={school.id}>
                                {schoolToEdit === school.id ? (
                                  <td>
                                    <input
                                      type='text'
                                      className='form-control w-50'
                                      placeholer='New School Name'
                                      value={newSchoolName}
                                      onChange={(e) =>
                                        setNewSchoolName(e.target.value)
                                      }
                                    />
                                  </td>
                                ) : (
                                  <td style={{textAlign: 'left'}}>{school.name}</td>
                                )}
                                <td>
                                  {schoolToEdit !== school.id ? (
                                    <React.Fragment>
                                      <button
                                        className='btn btn-sm btn-primary'
                                        data-title='Edit School'
                                        onClick={() => {
                                          setNewSchoolName(school.name);
                                          setSchoolToEdit(school.id);
                                        }}
                                      >
                                        <FA icon='edit' color='white' />
                                      </button>
                                      <button
                                        className='btn btn-sm btn-danger ml-2'
                                        data-title='Delete School'
                                        onClick={() => {
                                          setSchoolToDelete(school.id);
                                        }}
                                      >
                                        <FAW icon='trash' />
                                      </button>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {saveSpin && (
                                        <button
                                          className='btn btn-sm btn-primary'
                                          data-title='Saving...'
                                        >
                                          <FA
                                            color='white'
                                            icon='spinner'
                                            spin
                                          />
                                        </button>
                                      )}
                                      {!saveSpin && (
                                        <button
                                          className='btn btn-sm btn-primary mr-2'
                                          data-title='Save School'
                                          onClick={() => {
                                            handlePatch();
                                          }}
                                        >
                                          <FA icon='save' color='white' />
                                        </button>
                                      )}
                                      <button
                                        className='btn btn-sm btn-danger mr-2'
                                        data-title='Cancel'
                                        onClick={() => {
                                          setSchoolToEdit(null);
                                          setNewSchoolName("");
                                        }}
                                      >
                                        <FA color='white' icon='times' />
                                      </button>
                                    </React.Fragment>
                                  )}
                                </td>
                              </tr>
                            ))}
                          {filteredSchools && filteredSchools.length === 0 && (
                            <tr>
                              <td colSpan='2'>No Schools/Buyer Labels..</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default compose(
  withAPI
)(OrgSuperAdminSchoolsTable)
