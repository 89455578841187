import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { useDebounce } from "use-debounce";
import withAPI from "../../services/api";
import MainDashboard from "../../partials/Main";
import LearnerRow from "../../components/ManageAllLearners/LearnerRow.js";
import EditStudentModal from "../../components/ManageAllLearners/EditStudentModal.js";
import AddStudentsModal from "../../components/TeacherDashboard/AddStudentsModal";
import PaginationBar from "../../components/UtilComps/PaginationBar.js";
import BulkAddStudentsModal from "../../components/TeacherDashboard/BulkAddStudentsModal";
import FA from "../../containers/fa";
import {
  FormControl,
  Select,
  InputLabel,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./Dashboard.scss";
import InfoPopup from "../../components/UtilComps/InfoPopup";
import { useTitle } from "../../common/utils";
import {
  editableSubset,
  getAllSchoolInfo,
} from "../../components/InputFields/helpers";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  userId: state.auth.userId,
  robotType: state.status.robotType,
});

const Dashboard = ({ api, user }) => {
  useTitle("Learners");

  const [learners, setLearners] = useState([]);
  const [learnerSummaryStats, setLearnerSummaryStats] = useState(null);
  const [shouldSpin, setShouldSpin] = useState(true);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const currentPage = useRef(null);
  const [filterTeacher, setFilterTeacher] = useState([]);
  const [filterString, setFilterString] = useState("");
  const [filterMathLvl, setFilterMathLvl] = useState("");
  const [filterReadingLvl, setFilterReadingLvl] = useState("");
  const [filterEnrolled, setFilterEnrolled] = useState(null);
  const [filterGradeLock, setFilterGradeLock] = useState(null);
  const [filterSchools, setFilterSchools] = useState([]);
  const [schoolFilterOptions, setSchoolFilterOptions] = useState([]);
  const [fetchItemsTrigger, setFetchItemsTrigger] = useState(0);
  const [debouncedFetchItemsTrigger] = useDebounce(fetchItemsTrigger, 1000);
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false);
  const [showBulkAddStudentsModal, setShowBulkAddStudentsModal] =
    useState(false);
  const [showAutosave, setShowAutosave] = useState(false);
  const [autosaveSuccess, setAutosaveSuccess] = useState(null);
  const [showPopupInfo, setShowPopupInfo] = useState(false);
  const [popupInfo, setPopupInfo] = useState({
    header: "",
    text: "",
  });
  const [sortType, setSortType] = useState("user__last_name");
  const [sortAsc, setSortAsc] = useState(true);
  const [sortTouched, setSortTouched] = useState(false);

  const [editLearner, setEditLearner] = useState(null);

  const [teacherOptions, setTeacherOptions] = useState([]);

  let history = useHistory();

  const theme = createTheme({
    overrides: {
      MuiAutocomplete: {
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: "lightgray",
          },
          height: "50px",
          display: "flex",
          alignItems: "center",
        },
      },
    },
  });

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.from === "usage_stat"
    ) {
      setSortType("recent_weekly_lesson");
      setFilterEnrolled("yes");
    }
  }, [history]);

  const resetFilters = () => {
    setFilterString("");
    setFilterMathLvl("");
    setFilterReadingLvl("");
    setFilterSchools([]);
    setFilterEnrolled(null);
    setFilterGradeLock(null);
    setFilterTeacher([]);
  };

  const handleChange = (event, value) => {
    const emails = value.map((teacher) => teacher.email);
    setFilterTeacher(emails);
  };

  const resetBulkAddStudentsComponent = () => {
    // just turn it off and back on again
    // works until someone has the time and energy to implement an auto-reconnecting websocket hook
    setShowBulkAddStudentsModal(false);
    setTimeout(() => {
      setShowBulkAddStudentsModal(true);
    }, 10);
  };
  const fetchLearners = (pageNum, forceCalcStats, useCurrent = false) => {
    const calcStats =
      forceCalcStats || (learnerSummaryStats === null ? true : false);
    let queryParams = {
      q: filterString,
      teacherEmails: filterTeacher,
      page_size: pageSize,
      calc_stats: calcStats,
      sort: (sortAsc ? "" : "-") + sortType,
      math_level: filterMathLvl,
      reading_level: filterReadingLvl,
      orgunits: filterSchools,
      is_enrolled: filterEnrolled,
      is_grade_locked: filterGradeLock,
    };
    queryParams.page = pageNum;
    if (useCurrent) {
      queryParams.page = currentPage.current;
    }
    queryParams = Object.entries(queryParams).filter(([k, v]) => v);

    setShouldSpin(true);
    if (user) {
      api.fetchAllVisibleLearners(queryParams).then((objects) => {
        setLearners(objects.results);
        getAllSchoolInfo(api).then((res) => {
          const selectableSchools = editableSubset(res, user);
          setSchoolFilterOptions(selectableSchools);
        });

        delete objects["results"];
        if (calcStats) {
          setLearnerSummaryStats(objects.stats);
        }
        delete objects["stats"];
        setPaginationInfo(objects);
        currentPage.current = pageNum;
        setShouldSpin(false);
      });
    } else {
      setShouldSpin(false);
    }
  };

  useEffect(() => {
    api.fetchTeacherFilterOptions().then((res) => {
      setTeacherOptions(res);
    });
  }, []);

  // changes that would require recalculation of stats
  useEffect(() => {
    setFetchItemsTrigger(fetchItemsTrigger + 1);
  }, [
    filterString,
    filterEnrolled,
    filterMathLvl,
    filterReadingLvl,
    filterGradeLock,
    filterSchools,
    filterTeacher,
  ]);

  // do re-calcs
  useEffect(() => {
    if (debouncedFetchItemsTrigger > 0) {
      fetchLearners(null, true);
    }
  }, [debouncedFetchItemsTrigger]);

  // sort rows; no re-calcs
  useEffect(() => {
    if (sortTouched === true || pageSize !== null) {
      fetchLearners();
    }
  }, [sortType, sortAsc, pageSize]);

  const handleColumnHeaderClick = (column) => {
    setSortTouched(true);
    if (sortType === column) {
      setSortAsc(!sortAsc);
    } else {
      setSortType(column);
      setSortAsc(false);
    }
  };

  const getHeaderClasses = (column) => {
    let classes = "sortable";
    if (sortType === column) {
      if (sortAsc === false) {
        classes += " headerSortDown";
      } else {
        classes += " headerSortUp";
      }
    } else {
      classes += " headerSort";
    }
    return classes;
  };

  return (
    <MainDashboard>
      {showPopupInfo && (
        <InfoPopup popupInfo={popupInfo} setShowPopupInfo={setShowPopupInfo} />
      )}

      <div id={`autosave-${showAutosave}`}>
        <FA
          color={autosaveSuccess === false ? "#CB444A" : "#4D734D"}
          icon='spinner'
          spin
        />
        <p className={`autosave-caption-${autosaveSuccess}`}>
          {autosaveSuccess === null
            ? " Saving changes..."
            : autosaveSuccess
            ? " Saved"
            : " Autosave failed."}
        </p>
      </div>
      {showAddStudentsModal && (
        <AddStudentsModal
          setShowModal={setShowAddStudentsModal}
          assignClassId={null}
          setRefreshForNewLearners={fetchLearners}
        />
      )}
      {showBulkAddStudentsModal && (
        <BulkAddStudentsModal
          setShowModal={setShowBulkAddStudentsModal}
          setRefreshForNewLearners={fetchLearners}
          resetComponent={resetBulkAddStudentsComponent}
        />
      )}
      {editLearner && (
        <EditStudentModal
          editLearner={editLearner}
          setEditLearner={setEditLearner}
          refreshLearners={() => {
            fetchLearners(null, false, true);
          }}
        />
      )}
      <div className='common_border'>
        <div className='common_heading d-flex justify-content-between align-items-center'>
          <p>Learner Management Dashboard</p>
        </div>

        <div className='common_dashboard_bg' id='learners-wrapper'>
          {/* Only hide upon initial loading */}
          {(!shouldSpin || learnerSummaryStats !== null) && (
            <div className='row mb-2'>
              <div className='col-12'>
                <button
                  onClick={() => {
                    setShowAddStudentsModal(true);
                  }}
                  className='btn btn-md btn-primary mr-2'
                >
                  Create New Learners
                </button>
                <button
                  onClick={() => {
                    setShowBulkAddStudentsModal(true);
                  }}
                  className='btn btn-md btn-primary mr-2'
                >
                  Bulk Learner Management
                </button>
              </div>
            </div>
          )}

          <div className='table-filter-wrapper p-3 w-100 mb-2'>
            <div id='filters_fields_all_learners_container'>
              <h5 style={{ marginBottom: "0px" }}>Filters:</h5>
              <TextField
                margin='normal'
                placeholder='Search ...'
                disabled={shouldSpin}
                size='medium'
                value={filterString}
                onChange={(e) => setFilterString(e.target.value.toLowerCase())}
              />
              <FormControl>
                <InputLabel id='demo-simple-select-label'>
                  Math Level:
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  disabled={shouldSpin}
                  value={filterMathLvl || ""}
                  onChange={(e) => {
                    setFilterMathLvl(e.target.value);
                  }}
                >
                  <MenuItem value={"0"}>Grade K</MenuItem>
                  <MenuItem value={"1"}>Grade 1</MenuItem>
                  <MenuItem value={"2"}>Grade 2</MenuItem>
                  <MenuItem value={"3"}>Grade 3</MenuItem>
                  <MenuItem value={"4"}>Grade 4</MenuItem>
                  <MenuItem value={"5"}>Grade 5</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id='demo-simple-select-label'>
                  Reading Level:
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  disabled={shouldSpin}
                  value={filterReadingLvl || ""}
                  onChange={(e) => {
                    setFilterReadingLvl(e.target.value);
                  }}
                >
                  <MenuItem value={"0"}>Grade K</MenuItem>
                  <MenuItem value={"1"}>Grade 1</MenuItem>
                  <MenuItem value={"2"}>Grade 2</MenuItem>
                  <MenuItem value={"3"}>Grade 3</MenuItem>
                  <MenuItem value={"4"}>Grade 4</MenuItem>
                  <MenuItem value={"5"}>Grade 5</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id='demo-simple-select-label'>
                  School(s):
                </InputLabel>
                <Select
                  multiple
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  disabled={shouldSpin}
                  value={filterSchools}
                  onChange={(e) => {
                    setFilterSchools(e.target.value);
                  }}
                >
                  {[
                    schoolFilterOptions.map((school) => (
                      <MenuItem value={school.id}>{school.name}</MenuItem>
                    )),
                    <MenuItem value={"none"}>{"None"}</MenuItem>,
                  ]}
                </Select>
              </FormControl>
              <button
                id='filter_reset_btn_hide_mobile'
                className='btn btn-primary btn-sm mt-3 reset_filters_btn'
                style={{ justifySelf: "baseline" }}
                disabled={shouldSpin}
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset Filters
              </button>
              <span className='filter_checkboxes_container'>
                <h6 style={{ margin: "0px 10px 0px 0px" }}>Enrolled:</h6>
                <div className='filter_checkboxes' style={{ display: "flex" }}>
                  <div
                    className='admin_access_filter'
                    style={{ marginRight: "10px" }}
                  >
                    <p>Yes</p>
                    <input
                      type='checkbox'
                      checked={filterEnrolled === "yes"}
                      onChange={(e) => {
                        setFilterEnrolled(!e.target.checked ? null : "yes");
                      }}
                    />
                  </div>
                  <div
                    className='admin_access_filter'
                    style={{ justifySelf: "flex-end" }}
                  >
                    <p>No</p>
                    <input
                      type='checkbox'
                      checked={filterEnrolled === "no"}
                      onChange={(e) => {
                        setFilterEnrolled(e.target.checked ? "no" : null);
                      }}
                    />
                  </div>
                </div>
              </span>
              <span className='filter_checkboxes_container'>
                <h6 style={{ margin: "0px 10px 0px 0px" }}>Grade Locked:</h6>
                <div className='filter_checkboxes' style={{ display: "flex" }}>
                  <div
                    className='admin_access_filter'
                    style={{ marginRight: "10px" }}
                  >
                    <p>Yes</p>
                    <input
                      type='checkbox'
                      checked={filterGradeLock === "yes"}
                      onChange={(e) => {
                        setFilterGradeLock(!e.target.checked ? null : "yes");
                      }}
                    />
                  </div>
                  <div
                    className='admin_access_filter'
                    style={{ justifySelf: "flex-end" }}
                  >
                    <p>No</p>
                    <input
                      type='checkbox'
                      checked={filterGradeLock === "no"}
                      onChange={(e) => {
                        setFilterGradeLock(e.target.checked ? "no" : null);
                      }}
                    />
                  </div>
                </div>
              </span>
              <div style={{ gridColumn: "span 2" }}>
                <ThemeProvider theme={theme}>
                  <Autocomplete
                    multiple
                    options={teacherOptions}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name} <${option.email}>`
                    }
                    disabled={shouldSpin}
                    onChange={handleChange}
                    renderOption={(option, { selected }) => (
                      <div>
                        {`${option.first_name} ${option.last_name} <${option.email}>`}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Teachers:'
                        variant='standard'
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: filterTeacher.length
                            ? filterTeacher.map((email, index) => {
                                const teacher = teacherOptions.find(
                                  (teacher) => teacher.email === email
                                );
                                const isLast =
                                  index === filterTeacher.length - 1;
                                const comma = isLast ? "" : ",";
                                return (
                                  <span key={email} style={{ marginRight: 4 }}>
                                    {teacher.first_name} {teacher.last_name}
                                    {comma}
                                  </span>
                                );
                              })
                            : null,
                        }}
                      />
                    )}
                    value={teacherOptions.filter((teacher) =>
                      filterTeacher.includes(teacher.email)
                    )}
                    disableCloseOnSelect
                  />
                </ThemeProvider>
              </div>

              <button
                id='filter_reset_btn_show_mobile'
                className='btn btn-primary btn-sm mt-3 reset_filters_btn'
                style={{ justifySelf: "baseline" }}
                disabled={shouldSpin}
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset Filters
              </button>
            </div>
          </div>

          {learnerSummaryStats &&
            learnerSummaryStats !== null &&
            paginationInfo && (
              <div className='common_border w-100 mb-3'>
                <table className='table table-striped text-center mb-0'>
                  <thead>
                    <tr>
                      <th className='item-label'>
                        {"Total Learner Count:"}&nbsp;
                        {paginationInfo.count}
                      </th>
                      <th className='item-label'>
                        {"Enrolled Learners:"}&nbsp;
                        {learnerSummaryStats.enrolled_learners_count}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            )}

          <div>
            <PaginationBar
              paginationInfo={paginationInfo}
              fetchPageFunc={fetchLearners}
              loading={shouldSpin}
              setPageSize={setPageSize}
              pageSize={pageSize}
            />

            <div className='card'>
              <div
                className='progress-linear school_listing manage_learner_table visible-scrollbar'
                role='progressbar'
              >
                <div style={{ minWidth: "750px" }}>
                  <div className='class-grid-row-caption-container'>
                    <p></p>
                    <p
                      className={getHeaderClasses("user__last_name")}
                      onClick={() => {
                        handleColumnHeaderClick("user__last_name");
                      }}
                    >
                      Name
                    </p>

                    <span id='teacher_name_hr'>
                      <p>Teacher</p>
                    </span>

                    <span
                      id='learner_avg_lessons_hr'
                      className={getHeaderClasses("recent_weekly_lesson")}
                    >
                      <p
                        style={{ padding: "0" }}
                        onClick={() => {
                          handleColumnHeaderClick("recent_weekly_lesson");
                        }}
                      >
                        Recent Lessons
                      </p>

                      <button
                        className='info-circle-btn'
                        onClick={() => {
                          setPopupInfo({
                            header: "Recent Lessons",
                            text: `This represents the number of
                                    lessons taken by this learner in the last 30 days.
                                    An average of at least 1 lesson per week
                                    (4 lessons in the past 30 days)
                                    indicates that recommended usage is met.`,
                          });
                          setShowPopupInfo(true);
                        }}
                      >
                        <FA
                          icon='info-circle'
                          className='info-circle robots_table_hr'
                        />
                      </button>
                    </span>

                    <span className={getHeaderClasses("total_lessons")}>
                      <p
                        style={{ padding: "0" }}
                        onClick={() => {
                          handleColumnHeaderClick("total_lessons");
                        }}
                      >
                        Total Lessons
                      </p>

                      <button
                        className='info-circle-btn'
                        onClick={() => {
                          setPopupInfo({
                            header: "Total Lessons",
                            text: `This represents the total number of
                                    lessons taken by this learner.`,
                          });
                          setShowPopupInfo(true);
                        }}
                      >
                        <FA
                          icon='info-circle'
                          className='info-circle robots_table_hr'
                        />
                      </button>
                    </span>

                    <span>Enrolled</span>
                    <span>Password</span>
                    <span>Math Level</span>
                    <span>Reading Level</span>
                    <span className='hide_on_ipad'>
                      Grade Locked
                      <button
                        className='info-circle-btn'
                        onClick={() => {
                          setPopupInfo({
                            header: "Grade Lock",
                            text: `When a learner is grade locked, they cannot take any
                                lessons below or above their specified grade levels for
                                their subjects. This setting can be toggled on and off.`,
                          });
                          setShowPopupInfo(true);
                        }}
                      >
                        <FA
                          icon='info-circle'
                          className='info-circle robots_table_hr'
                        />
                      </button>
                    </span>
                    <span className='hide_on_ipad'>Actions</span>
                  </div>
                  {learners.length > 0 &&
                    paginationInfo &&
                    learners.map((lrn, index) => (
                      <LearnerRow
                        key={lrn.id}
                        lrn={lrn}
                        api={api}
                        index={paginationInfo.start_index + index}
                        setShowAutosave={setShowAutosave}
                        setAutosaveSuccess={setAutosaveSuccess}
                        setShowPopupInfo={setShowPopupInfo}
                        setPopupInfo={setPopupInfo}
                        setEditLearner={setEditLearner}
                      />
                    ))}
                  {shouldSpin && learners.length === 0 && (
                    <div className='p-2'>
                      <FA color='gray' icon='spinner' spin />
                      <span className='ml-2'>Loading Learners ...</span>
                    </div>
                  )}
                  {!shouldSpin && learners.length === 0 && (
                    <div className='p-2'>No learners found ...</div>
                  )}
                </div>
              </div>
            </div>

            <PaginationBar
              paginationInfo={paginationInfo}
              fetchPageFunc={fetchLearners}
              loading={shouldSpin}
              pageSize={pageSize}
            />
          </div>
        </div>
      </div>
    </MainDashboard>
  );
};

export default compose(connect(mapStateToProps), withAPI)(Dashboard);
