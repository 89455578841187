import moment from "moment";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {compose} from "redux";
import config from "../../common/config";
import {useTitle} from "../../common/utils";
import RenewalDetailPremiumCare from "../../components/ManageAllSubscriptions/DetailPages/RenewalDetailPremiumCare";
import FA from "../../containers/fa";
import MainDashboard from "../../partials/Main";
import withAPI from "../../services/api";
import "./Renewal.scss";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
});

const RenewalInfoContainer = ({api, user, match}) => {
  useTitle("Subscription Info");
  let history = useHistory();
  const subscriptionLabelMap = config.SUBSCRIPTION_TYPE_DISPLAY_NAMES;
  const [renewalInfo, setRenewalInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadRenewalInfo();
  }, []);

  const loadRenewalInfo = () => {
    api
      .fetchRenewalInfo(match.params.renewalId)
      .then((res) => {
        setRenewalInfo(res.data);
      })
      .catch((e) => {
        setError("Something went wrong ... Please try again.");
      });
  };

  return (
    <div>
      <MainDashboard>
        <div className='common_heading d-flex justify-content-between align-items-center'>
          <h4>Renewal Info</h4>
          &nbsp;
          <button
            className='btn btn-sm btn-primary'
            onClick={() => history.goBack()}
          >
            {" "}
            Back{" "}
          </button>
        </div>

        <div className='common_dashboard_bg'>
          <div className='row'>
            <div className='col-12'>
              {error && (
                <div className='alert alert-danger' role='alert'>
                  {error}
                </div>
              )}
              {!renewalInfo && !error && (
                <div>
                  <h4>
                    <FA color='gray' icon='spinner' spin /> Loading Renewal
                    info...
                  </h4>
                </div>
              )}

              {renewalInfo && (
                <div>
                  <div className='renewal-info-container mb-4'>
                    <div className='renewal-info-row'>
                      <span className='font-weight-bold'>Renewal Type</span>
                      <span>
                        {
                          subscriptionLabelMap[
                            renewalInfo.subscription_type_name
                          ]
                        }
                      </span>
                    </div>
                    {renewalInfo.processed_at ? (
                      <div className='renewal-info-row'>
                        <span className='font-weight-bold'>
                          Renewal Processed
                        </span>
                        <span>
                          {moment
                            .utc(renewalInfo.processed_at)
                            .local()
                            .format("MMMM Do YYYY")}
                        </span>
                      </div>
                    ) : (
                      <div className='renewal-info-row'>
                        <span className='font-weight-bold'>
                          Renewal to Take Effect On
                        </span>
                        <span>
                          {moment
                            .utc(renewalInfo.to_be_processed_at)
                            .local()
                            .format("MMMM Do YYYY")}
                        </span>
                      </div>
                    )}
                    <div className='renewal-info-row'>
                      <span className='font-weight-bold'>Renewal Count</span>
                      <span>
                        {renewalInfo.new_subscription &&
                          renewalInfo.new_subscription.subscriptionlimit_set[0]
                            .max_objects}
                      </span>
                    </div>
                    <div className='renewal-info-row'>
                      <span className='font-weight-bold'>Previous Count</span>
                      <span>{renewalInfo.prev_count}</span>
                    </div>
                  </div>

                  {renewalInfo.subscription_type_name === "premiumcare" && (
                    <RenewalDetailPremiumCare
                      renewalInfo={renewalInfo}
                      refreshRenewal={loadRenewalInfo}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </MainDashboard>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(RenewalInfoContainer);
