import React, { useEffect, useState } from "react";
import { compose } from "redux";
import withAPI from "../../services/api";
import FA from "../../containers/fa";
import "./UsageStatsRow.scss";
import ImpactStatsRow from "./ImpactStatsRow";
import config from "../../common/config";

const ImpactStats = ({ api, user, schoolsFilter }) => {
  const [data, setData] = useState(null);
  const [exportEnabled, setExportEnabled] = useState(true);

  const [showSubjectBreakdown, setShowSubjectBreakdown] = useState(false);

  useEffect(() => {
    let fetch = null;
    let data = null;

    switch (user.usertype) {
      case config.ORGADMIN_USERTYPE:
        if (schoolsFilter.length === 1) {
          data = schoolsFilter[0].id;
          fetch = api.fetchImpactStatsForOrgUnit;
        } else if (schoolsFilter.length > 1) {
          data = schoolsFilter.map((school) => school.id);
          fetch = api.fetchImpactStatsForMultipleOrgUnits;
        } else {
          data = user.organization.id;
          fetch = api.fetchImpactStatsForOrg;
        }
        break;
      case config.ORGUNITADMIN_USERTYPE:
        if (schoolsFilter.length === 1) {
          data = schoolsFilter[0].id;
          fetch = api.fetchImpactStatsForOrgUnit;
        } else if (schoolsFilter.length > 1) {
          data = schoolsFilter.map((school) => school.id);
          fetch = api.fetchImpactStatsForMultipleOrgUnits;
        } else {
          data = user.orgunits.map((orgunit) => orgunit.id);
          if (data.length === 1) {
            data = data[0];
            fetch = api.fetchImpactStatsForOrgUnit;
          } else {
            fetch = api.fetchImpactStatsForMultipleOrgUnits;
          }
        }
        break;
    }

    fetch(data)
      .then((res) => {
        console.log(res);
        setData(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [schoolsFilter]);

  const handleExportImpactStats = () => {
    setExportEnabled(false);
    let data = { orgId: user.organization.id };
    if (schoolsFilter.length > 0) {
      data = { orgunitIds: schoolsFilter.map((school) => school.id) };
    } else if (user.usertype == config.ORGUNITADMIN_USERTYPE) {
      /* If SchoolAdmin and no shool filter applied, request stats for all of
      * the user's orgunits rather than the entire Org */
      data = { orgunitIds: user.orgunits.map((ou) => ou.id) };
    }
    api.exportImpactStats(data).then((res) => {
      setExportEnabled(true);
    });
  };

  return (
    <div className='common_border'>
      <div className='common_heading'>
        <div
          style={{ margin: "0 15px" }}
          className='d-flex justify-content-between align-items-center'
        >
          <p>Impact Stats</p>

          {data && data.timestamps.length > 0 && (
            <button
              style={{ width: "200px", float: "right" }}
              className='btn btn-primary'
              disabled={!exportEnabled}
              onClick={() => {
                handleExportImpactStats();
              }}
            >
              Export Impact Stats
            </button>
          )}
        </div>
      </div>

      {data === null && (
        <div className='common_table_padding'>
          <div className='row batch-row'>
            <p>
              <FA icon='spinner' color='gray' spin />
              <span className='ml-2'>Loading Impact Stats ...</span>
            </p>
          </div>
        </div>
      )}

      {data && data.timestamps.length === 0 && (
        <div className='common_table_padding'>
          <div className='row batch-row'>
            <div className='col'>
              <p>
                No stats available. Impact Stats are updated daily once learners
                have started syncing their lessons.
              </p>
            </div>
          </div>
        </div>
      )}

      {data && data.timestamps.length > 0 && (
        <div>
          <ImpactStatsRow
            subject={"All"}
            allData={data}
            showBySubject={showSubjectBreakdown}
            setShowBySubject={setShowSubjectBreakdown}
          />
        </div>
      )}

      {data && data.timestamps.length > 0 && showSubjectBreakdown && (
        <div>
          <ImpactStatsRow subject={"Math"} allData={data} />

          <ImpactStatsRow subject={"Reading"} allData={data} />
        </div>
      )}
    </div>
  );
};

export default compose(withAPI)(ImpactStats);
