import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import config from "../../../common/config";
import withAPI from "../../../services/api";
import AddRobotPopup from "./AddRobotPopup";
import RobotSection from "./RobotSection";
import "./SubRobot.scss";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
});

const SubDetailPremiumCare = ({ user, subData, refreshSubs }) => {
  console.log(subData);

  const [showAddRobotPopup, setShowAddRobotPopup] = useState(false);
  const subLimit = subData.subscriptionlimit_set[0];
  const allowedCount = subLimit.max_objects;
  const usedCount = subData.utilized_count;

  const get_status = () => {
    if (!subData) {
      return null;
    }
    if (subData.active) {
      return "Active";
    } else if (
      subData.start_date &&
      moment.utc(subData.start_date).isAfter(moment())
    ) {
      return "Not Yet Active";
    } else if (
      subData.end_date &&
      moment.utc(subData.end_date).isBefore(moment())
    ) {
      return "Expired";
    } else {
      return "Inactive";
    }
  };

  const shouldShowRenewalWithoutRobotsMessage = () => {
    return (
      !subData.pending_renewal &&
      subData.is_renewal &&
      subData.active &&
      usedCount <= 0
    );
  };

  const AddRemoveRobotsButton = () => {
    return (
      <React.Fragment>
        <div>
          {usedCount >= allowedCount ? (
            <p className='mb-1'>
              All of the slots in this subscription are filled. Remove Robots
              from the list below to add other Robots into this Subscription.
            </p>
          ) : (
            <>
              {shouldShowRenewalWithoutRobotsMessage() && (
                <p>
                  This subscription has been renewed but there are no robots
                  attached. Click the 'Add Robots' button below to assign robots
                  to this subscription.
                </p>
              )}
              <button
                className='btn btn-md btn-primary'
                disabled={usedCount >= allowedCount}
                data-title={
                  usedCount >= allowedCount ? "All slots filled" : null
                }
                onClick={() => {
                  setShowAddRobotPopup(true);
                }}
              >
                Add Robots to this subscription...
              </button>
            </>
          )}
        </div>
        <p>
          Note: Only Robots newly assigned or added to the Subscription can be
          unassigned. Once the Robot updates, this assignment is final.
        </p>
      </React.Fragment>
    );
  };

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div className='row mb-10'></div>

          <div>
            {subData && (
              <div>
                <div className='subscription-info'>
                  <div>
                    <b>Status:</b>
                  </div>
                  <div>{get_status()}</div>

                  <div>
                    <b>Start Date:</b>
                  </div>
                  <div>
                    {moment
                      .utc(subData.start_date)
                      .local()
                      .format("MMM Do YYYY")}
                  </div>

                  <div>
                    <b>End Date:</b>
                  </div>
                  <div>
                    {moment.utc(subData.end_date).local().format("MMM Do YYYY")}
                  </div>

                  <div>
                    <b>Subscription ID:</b>
                  </div>
                  <div>{subData.id}</div>

                  {user.usertype === config.SUPERADMIN_USERTYPE && (
                    <React.Fragment>
                      <div>
                        <b>Associated Sale ID:</b>
                      </div>
                      <div>{subData.sale}</div>
                    </React.Fragment>
                  )}

                  <div>
                    <b>Usage:</b>
                  </div>
                  <div>
                    <span className='mr-2 font-weight-bold'>
                      {subData.utilized_count}
                    </span>
                    out of
                    <span className='mx-2 font-weight-bold'>
                      {subLimit.max_objects}
                    </span>
                    available slots used.
                  </div>
                </div>

                {/* SUPER ADMIN actions/notes */}
                {user.usertype === config.SUPERADMIN_USERTYPE && (
                  <React.Fragment>
                    {AddRemoveRobotsButton()}

                    {/* IF this is not a renewal, Robot list will populate based on Activation */}
                    {!subData.is_renewal && (
                      <p>
                        Robots are assigned to a Premium Care Subscription based
                        on the Activation Code used during Activation. This
                        table will populate automatically as Robots are
                        Activated.
                      </p>
                    )}
                  </React.Fragment>
                )}

                {/* ORG ADMIN actions/notes */}
                {user.usertype === config.ORGADMIN_USERTYPE ||
                  (user.usertype === config.ORGUNITADMIN_USERTYPE && (
                    <React.Fragment>
                      {/* 
                      IF this is an active renewal, allow ORGADMIN to manage
                      Robots. For full renewals, Robot list will automatically
                      populate and OrgAdmin will have nothing to do. For
                      partial renewals, they will need to choose Robots 
                    */}
                      {subData.is_renewal &&
                        subData.active &&
                        AddRemoveRobotsButton()}

                      {/* IF renewal is not active, Robots cannot be added/removed */}
                      {subData.is_renewal && !subData.active && (
                        <p>
                          Cannot manage Robots associated with a Subscription
                          while it is not Active.
                        </p>
                      )}

                      {/* IF this is not a renewal, Robot list will populate based on Activation */}
                      {!subData.is_renewal && (
                        <p>
                          Robots are assigned to a Premium Care Subscription
                          based on the Activation Code used during Activation.
                          This Subscription will populate automatically as
                          Robots are Activated.
                        </p>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            )}

            {showAddRobotPopup && (
              <AddRobotPopup
                objInfo={subData}
                parentType='subscription'
                refreshParent={refreshSubs}
                setShowAddRobotPopup={setShowAddRobotPopup}
              />
            )}

            {subData && (
              <RobotSection
                objInfo={subData}
                refreshParent={refreshSubs}
                parentType='subscription'
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(SubDetailPremiumCare);
