import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { removePrefix } from "../../common/utils";
import config from "../../common/config";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import UnassignDecoupleRobotModal from "../AppModals/UnassignDecoupleRobotModal";
import ExpirationStatus from "../UtilComps/ExpirationStatus";
import OnlineNowModal from "./OnlineNowModal";
import "./RobotRow.scss";
import UpdateRobotModal from "./UpdateRobotModal";

const RobotRow = ({
  api,
  rbt,
  ind,
  schoolOrgAdminView,
  superAdminView,
  usertype,
  showRemove,
  teachers,
  refreshFunction,
  showOnlineNow,
  updateAllInProgress,
  orgAdminMyRobotsView,
  superAdminOrgPageView,
  orgAdminManageTeacherView,
  variant,
  subOrRenewalId,
  handleSuccessRemoval,
  // for when checkbox input is displayed
  checkboxAddDisabled,
  checkboxChecked,
  handleCheckboxChange,
}) => {
  const subscriptionView = variant && variant.startsWith("subscription");
  const renewalView = variant && variant.startsWith("renewal");
  const addRobotView = variant && variant.endsWith("addRobotPopup");

  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);
  const [deleteModalUseCase, setDeleteModalUseCase] = useState(null);
  const [showRow, setShowRow] = useState(true);
  const [showOnlineNowModal, setShowOnlineNowModal] = useState(false);
  const [showUpdateRobotModal, setShowUpdateRobotModal] = useState(false);
  const [teacherList, setTeacherList] = useState([]);
  const [teacherEditable, setTeacherEditable] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [assignSpin, setAssignSpin] = useState(false);

  const accountsMap = {
    false: "No",
    null: "No",
    true: "Yes",
  };

  const lastWeek = moment().subtract(7, "days");
  const lastMonth = moment().subtract(30, "days");
  const lastSixMonths = moment().subtract(180, "days");

  const combinedSchoolNames =
    rbt && rbt.orgunits ? rbt.orgunits.map((ou) => ou.name).join(", ") : "";

  /**
   *
   * @param {*} timestamp the time to be compared to now
   * @returns string for the className and color of the last online check-in.
   */
  const syncTimeClass = (timestamp) => {
    if (!rbt.last_synced_on) {
      return null;
    }
    if (moment.utc(timestamp) < lastSixMonths) {
      return "alert-danger";
    }
    if (moment.utc(timestamp) < lastMonth) {
      return "alert-warning";
    }
    if (moment.utc(timestamp) < lastWeek) {
      return "alert-success-2";
    }
    return "alert-success";
  };

  const timestampFormat =
    subscriptionView || renewalView ? "MMM D, YYYY" : "M/D/YYYY h:mma";

  useEffect(() => {
    if (schoolOrgAdminView) {
      let mostTeachers = teachers.filter(
        (t) => t.username !== rbt.assignment.teacher
      );
      let emptyTeacher = [{ id: 0, username: "Unassigned" }];
      if (rbt.assignment.teacher === "Unassigned") {
        setTeacherList(emptyTeacher.concat(mostTeachers));
      } else {
        let currentAssign = teachers.filter(
          (t) => t.username == rbt.assignment.teacher
        );
        setTeacherList(currentAssign.concat(emptyTeacher.concat(mostTeachers)));
      }
    }
  }, [teachers, rbt, schoolOrgAdminView]);

  if (showRow === false) {
    return null;
  }

  /**
   * @param {String} val the value to be found and matched
   * @param {String} prop the key name
   * @param {*} array the array of objects to be searched
   * @returns the object whose value matches
   */
  const searchObjectArray = (val, prop, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i][prop] === val) {
        return array[i];
      }
    }
    return null;
  };

  /**
   * handleAssignment calls on the api to save the change in assigned
   * teacher, then updates the row and toggles editable to false.
   */
  const handleAssignment = () => {
    setAssignSpin(true);
    api
      .assignTeacherToRobot([rbt.serial], selectedTeacher.id)
      .then((resp) => {
        refreshFunction();
        setAssignSpin(false);
        setTeacherEditable(false);
      })
      .catch((e) => {
        setAssignSpin(false);
        console.log("Error'd:", e);
      });
  };

  const getSyncBtnTitle = () => {
    return updateAllInProgress
      ? "Update/Sync in progress"
      : !rbt.can_update
      ? "Robot subscription expired"
      : !rbt.online
      ? "Robot is offline"
      : "Update/Sync";
  };

  return (
    <div>
      <div
        key={rbt.serial}
        className={`robots_table_row${
          usertype === config.TEACHER_USERTYPE || orgAdminMyRobotsView
            ? "_my_robots"
            : superAdminView
            ? "_super_admin"
            : orgAdminManageTeacherView
            ? "_manage_teacher"
            : subscriptionView || renewalView
            ? "_subscription"
            : ""
        }`}
        style={{ backgroundColor: ind % 2 === 0 ? "white" : "" }}
      >
        {ind !== undefined && (
          <div style={{ justifyContent: "left" }}>
            <p className='pl-1' left-data-title='From orignal subscriptions'>
              {ind}
              {renewalView && rbt.is_from_old_subs && (
                <FA color='#ffc107' icon='star' className='ml-1' />
              )}
            </p>
          </div>
        )}

        {showOnlineNow && (
          <div>
            <div className='d-flex justify-content-center align-items-center'>
              {rbt.online && (
                <FA
                  color='green'
                  icon='circle'
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowOnlineNowModal(true)}
                  className='faa-pulse animated fa-xs'
                />
              )}
            </div>
          </div>
        )}

        <div>
          {rbt.serial && (
            <span 
              assignment-data-title={
                orgAdminManageTeacherView ? "" : rbt.serial
              }
              style={{
                width: "100%",
                textAlign: orgAdminManageTeacherView ? "center" : "left",
                position: "relative",
              }}
            >
              <Link style={{ color: "black" }} to={"/robot/" + rbt.serial}>
                {rbt.wifi}
              </Link>
            </span>
          )}
        </div>

        {orgAdminManageTeacherView && (
          <div style={{ hyphens: "auto", overflowWrap: "anywhere" }}>
            {rbt.serial && (
              <Link style={{ color: "black" }} to={"/robot/" + rbt.serial}>
                {rbt.serial}
              </Link>
            )}
          </div>
        )}

        <div>
          {rbt.serial && (
            <Link
              style={{ display: "flex", alignItems: "center", color: "black" }}
              to={"/robot/" + rbt.serial}
            >
              <p className='mr-1'>{rbt.code_ver}</p>
              {rbt.is_latest ? (
                <span
                  style={{ position: "relative" }}
                  assignment-data-title='Robot is up to date'
                >
                  <FA color='green' icon='check-circle' />
                </span>
              ) : (
                <span
                  style={{ position: "relative" }}
                  assignment-data-title='Update available'
                >
                  <FA color='#ffc107' icon='exclamation-circle' />
                </span>
              )}
            </Link>
          )}
        </div>

        <ExpirationStatus
          obj={rbt}
          containerStyle={{ textAlign: "center", position: "relative" }}
        />

        {!(subscriptionView || renewalView) && (
          <div>
            {rbt.serial && rbt.last_code_update ? (
              <p style={{ textAlign: "center" }}>
                {moment
                  .utc(rbt.last_code_update)
                  .local()
                  .format(timestampFormat)}
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>
        )}

        {!(subscriptionView || renewalView) && (
          <div>
            {rbt.serial && rbt.last_lesson_update ? (
              <p style={{ textAlign: "center" }}>
                {moment
                  .utc(rbt.last_lesson_update)
                  .local()
                  .format(timestampFormat)}
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>
        )}

        <div className={syncTimeClass(rbt.last_synced_on)}>
          {rbt.serial && rbt.last_synced_on ? (
            <p style={{ textAlign: "center" }}>
              {moment.utc(rbt.last_synced_on).local().format(timestampFormat)}
            </p>
          ) : (
            <p>N/A</p>
          )}
        </div>

        {schoolOrgAdminView && !orgAdminMyRobotsView && (
          <div>{rbt.serial && <p>{rbt.recent_learners}</p>}</div>
        )}

        {schoolOrgAdminView && !orgAdminMyRobotsView && (
          <div>{rbt.serial && <p>{rbt.recent_lessons}</p>}</div>
        )}

        {superAdminView && (
          <div>
            {rbt.serial && (
              <Link style={{ color: "black" }} to={"/robot/" + rbt.serial}>
                {accountsMap[rbt.cloud_accounts]}
              </Link>
            )}
          </div>
        )}

        {superAdminView && !superAdminOrgPageView && (
          <div>
            {rbt.serial && (
              <p style={{ width: "100%" }}>{rbt.assignment.organization}</p>
            )}
          </div>
        )}

        {superAdminView && (
          <div>
            {rbt.serial && (
              <p style={{ width: "100%" }}>{combinedSchoolNames}</p>
            )}
          </div>
        )}

        {superAdminView && !superAdminOrgPageView && (
          <div>
            {rbt.serial && (
              <p style={{ textTransform: "capitalize" }}>
                {rbt.assignment.organization_type || " - "}
              </p>
            )}
          </div>
        )}

        {superAdminView && !superAdminOrgPageView && (
          <div>
            {rbt.serial && <p className='batch_name'>{rbt.batch_name}</p>}
          </div>
        )}

        {schoolOrgAdminView && !orgAdminMyRobotsView && (
          <div className='admin_school_name hide_on_ipad'>
            {rbt.serial && (
              <p style={{ width: "100%" }}>{combinedSchoolNames}</p>
            )}
          </div>
        )}

        {/* Superadmin org page version only */}
        {superAdminView && superAdminOrgPageView && (
          <div>
            {rbt.basic_use_stat && (
              <p>{rbt.basic_use_stat.unique_learners_with_lesson}</p>
            )}
          </div>
        )}

        {superAdminView && superAdminOrgPageView && (
          <div>
            {rbt.basic_use_stat && (
              <p>{rbt.basic_use_stat.total_lessons_taken}</p>
            )}
          </div>
        )}
        {/* end org page version */}

        {(subscriptionView || renewalView) && (
          <React.Fragment>
            <div>{rbt.serial && <p>{rbt.recent_learners}</p>}</div>
            <div>{rbt.serial && <p>{rbt.recent_lessons}</p>}</div>
            <div>{rbt.serial && <p>{combinedSchoolNames}</p>}</div>
            <div
              className='d-flex flex-flow-column justify-content-center'
              style={{
                flexFlow: "column",
                wordBreak: "break-word",
              }}
            >
              {rbt.assignment.teacher_name && (
                <p
                  style={{ margin: "0px", padding: "0px" }}
                  title={rbt.assignment.teacher}
                >
                  {removePrefix(rbt.assignment.teacher_name, "placeholder+")}
                </p>
              )}
            </div>
          </React.Fragment>
        )}

        {/* Teacher info */}
        {schoolOrgAdminView && !orgAdminMyRobotsView && teacherEditable ? (
          <div
            className='hide_on_ipad'
            style={{ float: "left", "min-width": "125px" }}
          >
            <select
              className='form-control input-sm'
              onChange={(e) => {
                let teacher = searchObjectArray(
                  e.target.value,
                  "username",
                  teacherList
                );
                setSelectedTeacher(teacher);
              }}
            >
              {teacherList.map((teacher, i) => (
                <option key={teacher.id} value={teacher.username}>
                  {removePrefix(teacher.username, "placeholder+")}
                </option>
              ))}
            </select>
          </div>
        ) : (
          schoolOrgAdminView &&
          !orgAdminMyRobotsView && (
            <div
              className='d-flex flex-flow-column justify-content-center hide_on_ipad'
              style={{
                alignItems: "flex-start",
                flexFlow: "column",
                wordBreak: "break-word",
              }}
            >
              {rbt.assignment.teacher_name && (
                <p style={{ margin: "0px", padding: "0px" }}>
                  {removePrefix(rbt.assignment.teacher_name, "placeholder+")}
                </p>
              )}
              <p style={{ margin: "0px", padding: "0px" }}>
                {removePrefix(rbt.assignment.teacher, "placeholder+")}
              </p>
            </div>
          )
        )}

        {schoolOrgAdminView && !orgAdminMyRobotsView && teacherEditable ? (
          <div className='d-flex p-2 align-self-center'>
            {assignSpin ? (
              <button
                assignment-data-title='Saving...'
                className='btn btn-sm btn-secondary hide_on_ipad'
                style={{ position: "relative" }}
                onClick={() => {
                  handleAssignment();
                }}
              >
                <FA color='white' icon='spinner' spin />
              </button>
            ) : (
              <button
                assignment-data-title='Save change'
                className='btn btn-sm btn-primary hide_on_ipad'
                style={{ position: "relative" }}
                onClick={() => {
                  handleAssignment();
                }}
              >
                <FA color='white' icon='save' />
              </button>
            )}
            <button
              assignment-data-title='Cancel'
              className='btn btn-sm btn-danger ml-1 hide_on_ipad'
              style={{ position: "relative" }}
              onClick={() => {
                setTeacherEditable(false);
              }}
            >
              <FA color='white' icon='times' />
            </button>

            <button
              assignment-data-title={getSyncBtnTitle()}
              style={{ position: "relative" }}
              className={`btn btn-sm ml-2 hide_on_ipad
                   ${
                     rbt.can_update && rbt.online
                       ? "btn-primary"
                       : "btn-secondary"
                   }`}
              onClick={() => {
                setShowUpdateRobotModal(true);
              }}
              disabled={updateAllInProgress || !rbt.online || !rbt.can_update}
            >
              <FA
                color='white'
                icon={rbt.can_update ? "sync" : "calendar-times"}
              />
            </button>
          </div>
        ) : (
          schoolOrgAdminView &&
          !orgAdminMyRobotsView && (
            <div
              className='p-2 align-self-center ml-1 hide_on_ipad'
              style={{ minWidth: "90px" }}
            >
              <button
                assignment-data-title='Re-assign this robot'
                style={{ position: "relative" }}
                className='btn btn-sm btn-primary'
                onClick={() => {
                  setTeacherEditable(true);
                }}
              >
                <FA color='white' icon='edit' />
              </button>
              <button
                assignment-data-title={getSyncBtnTitle()}
                className={`btn btn-sm ml-2
                   ${
                     rbt.can_update && rbt.online
                       ? "btn-primary"
                       : "btn-secondary"
                   }`}
                style={{ position: "relative" }}
                onClick={() => {
                  setShowUpdateRobotModal(true);
                }}
                disabled={updateAllInProgress || !rbt.online || !rbt.can_update}
              >
                <FA
                  color='white'
                  icon={rbt.can_update ? "sync" : "calendar-times"}
                />
              </button>
            </div>
          )
        )}

        {(subscriptionView || renewalView) && (
          <div>
            {addRobotView ? (
              <input
                type='checkbox'
                name='rbt-checkbox'
                className='form-control larger-checkbox'
                checked={checkboxChecked}
                disabled={checkboxAddDisabled && !checkboxChecked}
                onChange={() => {
                  handleCheckboxChange(rbt.serial);
                }}
              />
            ) : (
              <button
                subscription-data-title={
                  subscriptionView && !rbt.can_be_unassigned_from_regcode
                    ? "Cannot remove from subscription"
                    : subscriptionView
                    ? "Remove from subscription"
                    : "Remove from selection"
                }
                className={`btn btn-sm ml-2
                   ${
                     renewalView || rbt.can_be_unassigned_from_regcode
                       ? "btn-danger"
                       : "btn-secondary"
                   }`}
                style={{ position: "relative" }}
                onClick={() => {
                  setDeleteModalUseCase(
                    subscriptionView ? "subscription" : "renewal"
                  );
                  setShouldShowDeleteModal(true);
                }}
                disabled={
                  subscriptionView && !rbt.can_be_unassigned_from_regcode
                }
              >
                <FA color='white' icon='minus' />
              </button>
            )}
          </div>
        )}

        {/* Update/Sync Robot button for teacher */}
        {(usertype === config.TEACHER_USERTYPE || orgAdminMyRobotsView) && (
          <div>
            <button
              assignment-data-title={getSyncBtnTitle()}
              className={`btn btn-sm ml-2
                   ${rbt.can_update ? "btn-primary" : "btn-secondary"}`}
              style={{ position: "relative" }}
              onClick={() => {
                setShowUpdateRobotModal(true);
              }}
              disabled={updateAllInProgress || !rbt.online || !rbt.can_update}
            >
              <FA
                color='white'
                icon={rbt.can_update ? "sync" : "calendar-times"}
              />
            </button>
          </div>
        )}

        {showRemove && (
          <div>
            {rbt.serial && (
              <button
                className='btn btn-sm btn-danger'
                onClick={() => {
                  setDeleteModalUseCase("unassign");
                  setShouldShowDeleteModal(true);
                }}
              >
                <FA icon='trash-alt' color='white' />
              </button>
            )}
          </div>
        )}

        {shouldShowDeleteModal && (
          <UnassignDecoupleRobotModal
            useCase={deleteModalUseCase}
            rbtSerial={rbt.serial}
            parentObjId={subOrRenewalId}
            shouldShowFunc={setShouldShowDeleteModal}
            handleConfirmSuccess={() => {
              setShowRow(false);
            }}
            handleRemoveSuccess={handleSuccessRemoval}
          />
        )}

        {showOnlineNowModal && (
          <OnlineNowModal
            rbt={rbt}
            shouldShowFunc={setShowOnlineNowModal}
            setShowUpdateRobotModal={setShowUpdateRobotModal}
            statusReloadFunc={refreshFunction}
          />
        )}

        {showUpdateRobotModal && (
          <UpdateRobotModal
            rbt={rbt}
            shouldShowFunc={setShowUpdateRobotModal}
            statusReloadFunc={refreshFunction}
            usertype={usertype}
          />
        )}
      </div>

      {/* This is the version of the School, Teacher Info, and Actions
        column that will only show when the width is reduced to ipad size. */}
      {schoolOrgAdminView && !orgAdminMyRobotsView && (
        <div
          className='robot_actions_show_on_ipad robot_show_on_ipad_container'
          style={{ backgroundColor: ind % 2 === 0 ? "white" : "" }}
        >
          <p className='show_on_ipad_hr'>School</p>
          <p className='show_on_ipad_hr'>Teacher Info</p>
          <p className='show_on_ipad_hr'>Actions</p>

          <div className='admin_school_name hide_on_ipad'>
            {rbt.serial && (
              <p style={{ width: "100%" }}>{combinedSchoolNames}</p>
            )}
          </div>

          {/* Teacher info */}
          {teacherEditable ? (
            <div
              className='hide_on_ipad'
              style={{ float: "left", "min-width": "125px" }}
            >
              <select
                className='form-control input-sm'
                onChange={(e) => {
                  let teacher = searchObjectArray(
                    e.target.value,
                    "username",
                    teacherList
                  );
                  setSelectedTeacher(teacher);
                }}
              >
                {teacherList.map((teacher, i) => (
                  <option key={teacher.id} value={teacher.username}>
                    {removePrefix(teacher.username, "placeholder+")}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div
              className='d-flex flex-flow-column justify-content-center hide_on_ipad'
              style={{
                alignItems: "flex-start",
                flexFlow: "column",
                wordBreak: "break-word",
              }}
            >
              {rbt.assignment.teacher_name && (
                <p style={{ margin: "0px", padding: "0px" }}>
                  {removePrefix(rbt.assignment.teacher_name, "placeholder+")}
                </p>
              )}
              <p style={{ margin: "0px", padding: "0px" }}>
                {removePrefix(rbt.assignment.teacher, "placeholder+")}
              </p>
            </div>
          )}

          {teacherEditable ? (
            <div className='d-flex p-2 align-self-center'>
              {assignSpin ? (
                <button
                  assignment-data-title='Saving...'
                  className='btn btn-sm btn-secondary hide_on_ipad'
                  style={{ position: "relative" }}
                  onClick={() => handleAssignment()}
                >
                  <FA color='white' icon='spinner' spin />
                </button>
              ) : (
                <button
                  assignment-data-title='Save change'
                  className='btn btn-sm btn-primary hide_on_ipad'
                  style={{ position: "relative" }}
                  onClick={() => handleAssignment()}
                >
                  <FA color='white' icon='save' />
                </button>
              )}
              <button
                assignment-data-title='Cancel'
                className='btn btn-sm btn-danger ml-1 hide_on_ipad'
                style={{ position: "relative" }}
                onClick={() => setTeacherEditable(false)}
              >
                <FA color='white' icon='times' />
              </button>
              <button
                assignment-data-title={getSyncBtnTitle()}
                className={`btn btn-sm ml-2
                  hide_on_ipad ${
                    rbt.can_update ? "btn-primary" : "btn-secondary"
                  }`}
                style={{ position: "relative" }}
                onClick={() => setShowUpdateRobotModal(true)}
                disabled={updateAllInProgress || !rbt.online || !rbt.can_update}
              >
                <FA
                  color='white'
                  icon={rbt.can_update ? "sync" : "calendar-times"}
                />
              </button>
            </div>
          ) : (
            <div
              className='p-2 align-self-center ml-1 hide_on_ipad'
              style={{ minWidth: "90px" }}
            >
              <button
                assignment-data-title='Re-assign this robot'
                className='btn btn-sm btn-primary'
                style={{ position: "relative" }}
                onClick={() => setTeacherEditable(true)}
              >
                <FA color='white' icon='edit' />
              </button>
              <button
                assignment-data-title={getSyncBtnTitle()}
                className={`btn btn-sm
                  ml-2 ${rbt.can_update ? "btn-primary" : "btn-secondary"}`}
                style={{ position: "relative" }}
                onClick={() => setShowUpdateRobotModal(true)}
                disabled={updateAllInProgress || !rbt.online}
              >
                <FA
                  color='white'
                  icon={rbt.can_update ? "sync" : "calendar-times"}
                />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default compose(withAPI)(RobotRow);
