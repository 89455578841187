import React from "react";
import config from "../../common/config";
import { getDateTimeStringPerGivenFormat } from "../../common/utils";
import FA from "../../containers/fa";
import ExpirationStatus from "../UtilComps/ExpirationStatus";
import "./SubscriptionStyles.scss";

const SubscriptionRenewalRows = ({
  subscriptions,
  includeSelection,
  selectedSubscriptionIds,
  setSelectedSubscriptionIds,
}) => {
  const subscriptionLabelMap = config.SUBSCRIPTION_TYPE_DISPLAY_NAMES;
  let subscriptionsBySale = {};
  subscriptions.forEach((el, index) => {
    if (subscriptionsBySale[el.sale]) {
      subscriptionsBySale[el.sale].push(el.id);
    } else {
      subscriptionsBySale[el.sale] = [el.id];
    }
  });

  const handleCheckboxChange = (sub) => {
    // add or remove sub.id from selected array
    // and apply the same change to all subs in same sale
    const _tmp = new Set(selectedSubscriptionIds);
    const subsInSale = subscriptionsBySale[sub.sale];
    if (!_tmp.has(sub.id)) {
      subsInSale.forEach((item) => _tmp.add(item));
    } else {
      subsInSale.forEach((item) => _tmp.delete(item));
    }
    setSelectedSubscriptionIds(_tmp);
  };

  const getSubsGroupedBySale = () => {
    let subsBySale = {};
    subscriptions.forEach((sub) => {
      if (subsBySale[sub.sale]) {
        subsBySale[sub.sale].push(sub);
      } else {
        subsBySale[sub.sale] = [sub];
      }
    });
    return subsBySale;
  };

  const getPremiumCareAndStandaloneLctSubs = () => {
    let subsBySale = getSubsGroupedBySale();
    let premiumCareAndStandaloneLctSubs = [];
    Object.keys(subsBySale).forEach((sale) => {
      if (subsBySale[sale].length > 1) {
        let premiumCareSub = subsBySale[sale].find(
          (sub) => sub.subscription_type === "premiumcare"
        );
        premiumCareAndStandaloneLctSubs.push(premiumCareSub);
      } else {
        premiumCareAndStandaloneLctSubs.push(subsBySale[sale][0]);
      }
    });
    return premiumCareAndStandaloneLctSubs;
  };

  const subscriptionsForRenewal =
    subscriptions && subscriptions.length > 0
      ? getPremiumCareAndStandaloneLctSubs()
      : [];

  return (
    <div className='subscription-type-container'>
      <div className='subscription_row_renewal_hr'>
        <div>Sale Id</div>
        <div>Sub. Type</div>
        <div>Active</div>
        <div>Expiration Date</div>
        <div>Sub. Count</div>
        <div>Last Renewed</div>
        <div>{includeSelection ? "Select" : ""}</div>
      </div>

      <div id='subscription_row_renewal_rows_container'>
        {subscriptionsForRenewal.map((sub, index) => (
          <div className={"subscription_row_renewal_data"} key={sub.id}>
            <div>
              <p
                style={{
                  textAlign: "left",
                }}
              >
                {sub.sale}
              </p>
            </div>
            <div>
              <p style={{ textAlign: "left" }}>
                {subscriptionLabelMap[sub.subscription_type]}
              </p>
            </div>
            <div>
              <p>
                {sub.active ? (
                  <span>
                    Yes
                    <FA icon='check' className='has-wifi status-icon ml-1' />
                  </span>
                ) : (
                  <span>No</span>
                )}
              </p>
            </div>
            <ExpirationStatus
              containerStyle={{ textAlign: "left" }}
              obj={sub}
            />
            <div>
              <p style={{ textAlign: "left" }}>
                {sub.subscriptionlimit_set[0].max_objects}
              </p>
            </div>
            <div style={{ textAlign: "left" }}>
              <p>
                {sub.last_renewed_on
                  ? getDateTimeStringPerGivenFormat(
                      sub.last_renewed_on,
                      "MMMM Do YYYY"
                    )
                  : "-"}
              </p>
            </div>
            <div>
              {includeSelection && (
                <React.Fragment>
                  {sub.pending_renewal ? (
                    <span>Pending</span>
                  ) : (
                    <input
                      type='checkbox'
                      name='sub-checkbox'
                      className='form-control larger-checkbox'
                      checked={selectedSubscriptionIds.has(sub.id)}
                      onChange={() => {
                        handleCheckboxChange(sub);
                      }}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionRenewalRows;
