import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { useDebounce } from "use-debounce";
import config from "../../../common/config";
import { arrParams2Str, getTotalCount } from "../../../common/utils";
import FA from "../../../containers/fa";
import {
  refreshSaleDetail as refreshSaleDetailA,
  refreshSubscriptionItems as refreshSubscriptionItemsA,
  saleModalHeader as saleModalHeaderA,
} from "../../../redux/actions";
import withAPI from "../../../services/api";
import SubscriptionRenewalRows from "../../ManageAllSubscriptions/SubscriptionRenewalRows";
import CreateSuperAdminSchoolModal from "../Schools/CreateSuperAdminSchoolModal";
import "./SalesInfoDetailTab.scss";

const mapStateToProps = (state, ownProps?) => ({
  saleDetailId: state.superAdmin.saleDetailId,
  saleDetailOrgInfo: state.superAdmin.saleDetailOrgInfo,
  refreshSaleDetail: state.superAdmin.refreshSaleDetail,
  refreshSubscriptionItems: state.superAdmin.refreshSubscriptionItems,
  renewSubscriptionIds: state.superAdmin.renewSubscriptionIds,
});

const SalesInfoDetailTab = ({
  api,
  dispatch,
  saleObj,
  saleDetailId,
  saleDetailOrgInfo,
  refreshSaleDetail,
  refreshSubscriptionItems,
  renewSubscriptionIds,
}) => {
  // If saleObj is {}, we initiating a new Sale/Renewal
  // Org Id is determined by url param match or via saleDetailOrgInfo

  // For subscription renewal sales, we've decided to only show one unified field
  // for LCT + premiumcare, but the bulk of this code and backend code
  // is still keeping them separate for now.

  const [subscriptionsReady, setSubscriptionsReady] = useState(false);
  const [pricesReady, setPricesReady] = useState(false);

  const [showCreateSchoolModal, setShowCreateSchoolModal] = useState(false);
  const [schoolsInOrg, setSchoolsInOrg] = useState([]);
  const [schoolOfSale, setSchoolOfSale] = useState(null);

  const [fetchedPrices, setFetchedPrices] = useState({});
  // edit sale items & prices
  const [editItems, setEditItems] = useState(false);
  const [newItems, setNewItems] = useState({});
  const [debouncedNewItems] = useDebounce(newItems, 500);
  const [newPrices, setNewPrices] = useState({});
  // edit basic info items
  const [editBasics, setEditBasics] = useState(false);
  const [newBasics, setNewBasics] = useState({});
  const [newShippingAddress, setNewShippingAddress] = useState({});
  const [newBillingAddress, setNewBillingAddress] = useState({});
  const [orgAddress, setOrgAddress] = useState(null);

  // TODO: as we plan for more types of subscriptions,
  // shall query backend for list of subscription types info
  // and build the corresponding section.
  const [lctSubscriptionLength, setLctSubscriptionLength] = useState(12);
  const [premiumCareSubscriptionLength, setPremiumCareSubscriptionLength] =
    useState(12);

  // renewals
  const [renewSubscriptionInfo, setRenewSubscriptionInfo] = useState([]);

  const [saleReturns, setSaleReturns] = useState([]);
  const [returnData, setReturnData] = useState({});

  const [calcs, setCalcs] = useState({});

  const [spin, setSpin] = useState(false);
  const [error, setError] = useState("");

  const subscriptionLengthChoices = {
    "1 Year": 12,
    "2 Years": 24,
    "3 Years": 36,
  };

  const roundValues = (value, roundTo) => {
    if (value === "" || value === "-") {
      return value;
    }
    if (roundTo) {
      const p = Math.pow(10, roundTo);
      return Math.round(value * p + Number.EPSILON) / p;
    } else {
      return value;
    }
  };

  const fetchSaleReturns = () => {
    if (saleObj && saleObj.id && saleObj.has_returns) {
      console.log("*** fetching returns ***");
      api
        .fetchSaleReturns(saleObj.id)
        .then((res) => {
          console.log(res);
          setSaleReturns(res);
        })
        .catch((e) => {
          console.log("Error fetching returns.");
          console.log(e);
        });
    }
  };

  const roundPrices = (value) => {
    return roundValues(value, 2);
  };

  const monthToChoices = Object.assign(
    {},
    ...Object.entries(subscriptionLengthChoices).map(([a, b]) => ({ [b]: a }))
  );

  useEffect(() => {
    resetItems();
    resetBasics();
    fetchSaleReturns();
  }, [saleObj, fetchedPrices, renewSubscriptionInfo]);

  // renewal related
  useEffect(() => {
    let renewSubscriptionIdsFromSale;
    if (
      saleObj.old_subscription_ids &&
      saleObj.old_subscription_ids.length > 0
    ) {
      renewSubscriptionIdsFromSale = saleObj.old_subscription_ids;
    }

    if (renewSubscriptionIds || renewSubscriptionIdsFromSale) {
      setSubscriptionsReady(false);
      const subArrToQuery =
        renewSubscriptionIds || renewSubscriptionIdsFromSale;
      const queryParamsStr = arrParams2Str("subscriptionIds", subArrToQuery);
      setSpin(true);
      api
        .fetchSubscriptions(queryParamsStr)
        .then((res) => {
          setRenewSubscriptionInfo(res.results);
          setSubscriptionsReady(true);
          setSpin(false);
          // if Subscription(s) being renewed have a buyer label associated
          // with them, set that on page load, as long as only one such label
          // is present in the Subscription(s) being renewed.
          let buyer_label_set = res.results.reduce((accum, subscription) => {
            if (subscription.buyer_label && subscription.buyer_label.id) {
              accum.add(subscription.buyer_label.id);
            }
            return accum;
          }, new Set());
          let label_ids = Array.from(buyer_label_set);
          if (label_ids && label_ids.length === 1) {
            setSchoolOfSale(label_ids[0]);
          }
        })
        .catch((error) => {
          console.log(error);
          setError("An error occurred. Please try again later.");
          setSpin(false);
        });
    } else {
      setSubscriptionsReady(true);
    }
  }, [renewSubscriptionIds, saleObj]);

  const subscriptionRenewCountByType = () => {
    let res = { lct: 0, premiumcare: 0 };
    renewSubscriptionInfo.forEach((sub) => {
      if (sub.subscription_type in res) {
        res[sub.subscription_type] += sub.subscriptionlimit_set[0].max_objects;
      } else {
        res[sub.subscription_type] = sub.subscriptionlimit_set[0].max_objects;
      }
    });
    return res;
  };

  let isNewSale = false;
  if (saleDetailId === "new" || saleDetailId === "newRenew") {
    isNewSale = true;
  }

  let saleType = "regular";
  if (saleDetailId === "newRenew" || saleObj.sale_type === "renewal") {
    saleType = "renewal";
  }

  const renderSubscriptionCountWarningMessages = (subType) => {
    if (!subscriptionsReady || (!isNewSale && !saleObj.can_edit_items)) {
      return;
    }

    if (subType === "premiumcare") {
      if (
        subscriptionRenewCountByType().premiumcare >
        debouncedNewItems.premiumcare_count
      ) {
        return (
          <div className='alert alert-warning'>
            <strong>ABii Premium Care: </strong>
            The specified renewal count ({debouncedNewItems.premiumcare_count})
            is <strong>less than</strong> the total count from the selected
            Premium Care subscriptions (
            {subscriptionRenewCountByType().premiumcare}). This will result in a
            "Partial Renewal", which, once finalized, would require the Org
            Admin to specify the exact robots to be migrated into the new
            Subscription.
          </div>
        );
      } else if (
        subscriptionRenewCountByType().premiumcare <
        debouncedNewItems.premiumcare_count
      ) {
        return (
          <div className='alert alert-warning'>
            <strong>ABii Premium Care: </strong>
            The specified renewal count ({debouncedNewItems.premiumcare_count})
            is <strong>more than</strong> the total count from the selected
            Premium Care subscriptions (
            {subscriptionRenewCountByType().premiumcare}). This is fine, but
            please make sure that is intended.
          </div>
        );
      }
    }

    if (subType === "lct") {
      if (subscriptionRenewCountByType().lct > debouncedNewItems.lct_count) {
        return (
          <div className='alert alert-warning'>
            <strong>LCT: </strong>
            The specified renewal count ({debouncedNewItems.lct_count}) is{" "}
            <strong>less than</strong> the total count from the selected LCT
            subscriptions ({subscriptionRenewCountByType().lct}). This will
            result in a "Partial Renewal", which could result in some current
            LCT users losing access, and may require the Org Admin to reassign
            LCT subscriptions.
          </div>
        );
      } else if (
        subscriptionRenewCountByType().lct < debouncedNewItems.lct_count
      ) {
        return (
          <div className='alert alert-warning'>
            <strong>LCT: </strong>
            The specified renewal count ({debouncedNewItems.lct_count}) is{" "}
            <strong>more than</strong> the total count from the selected LCT
            subscriptions ({subscriptionRenewCountByType().lct}). This is fine,
            but please make sure that is intended.
          </div>
        );
      }
    }
  };

  useEffect(() => {
    if (saleReturns.length > 0) {
      setReturnData({
        robot_count: getTotalCount(saleReturns, "robot_count"),
        refund_amount: getTotalCount(saleReturns, "refund_amount", 2),
      });
    }
  }, [saleReturns]);

  useEffect(() => {
    if (saleDetailId === "new" || saleDetailId === "newRenew") {
      setPricesReady(false);
      setSpin(true);
      api
        .fetchLatestPrices()
        .then((res) => {
          let prices = {};
          res.forEach((item) => {
            prices[item.name] = roundPrices(item.value);
          });
          setFetchedPrices(prices);
          setPricesReady(true);
          setSpin(false);
        })
        .catch((error) => {
          console.log("Error fetching prices.");
          console.log(error);
          setSpin(false);
          setError("An error occurred. Please try again later.");
        });
    } else {
      setPricesReady(true);
    }
  }, [saleDetailId]);

  useEffect(() => {
    if (saleDetailOrgInfo.orgId) {
      setSpin(true);
      api
        .fetchOrganizationInfo(saleDetailOrgInfo.orgId)
        .then((res) => {
          const tmpAddress = {
            contact_name: res.contact_name,
            phone: res.phone,
            address1: res.address1,
            address2: res.address2,
            city: res.city,
            state: res.state,
            zipcode: res.zipcode,
            email: res.email,
          };
          if (res.state && res.state.length == 2) {
            tmpAddress["country"] = "USA";
          }
          setOrgAddress(tmpAddress);
          setSpin(false);
        })
        .catch((error) => {
          setOrgAddress(null);
          console.log("Error fetching org address: ", error);
          setSpin(false);
          setError("An error occurred. Please try again later.");
        });
      // grab list of schools if district level org
      if (saleDetailOrgInfo.orgLevel === config.ORG_LEVEL_DISTRICT) {
        refreshSchoolList();
      }
    }
  }, [saleDetailOrgInfo]);

  useEffect(() => {
    // update calculations when necessary
    refreshCalc();
  }, [
    newPrices,
    newItems,
    lctSubscriptionLength,
    premiumCareSubscriptionLength,
  ]);

  const resetItems = () => {
    // reset values to match those from saleObj
    let pdDict = {};
    if (saleObj.professional_developments) {
      for (let el of saleObj.professional_developments) {
        pdDict[`${el.pd_type}`] = el.count;
      }
    }
    if (saleObj.lct_sale) {
      setLctSubscriptionLength(saleObj.lct_sale.duration_months);
    } else {
      setLctSubscriptionLength(12);
    }
    if (saleObj.premiumcare_sale) {
      setPremiumCareSubscriptionLength(
        saleObj.premiumcare_sale.duration_months
      );
    } else {
      setPremiumCareSubscriptionLength(12);
    }

    let lct_count = 0;
    if (saleObj.lct_sale) {
      lct_count = saleObj.lct_sale.count;
    } else if (saleDetailId === "newRenew") {
      // For now set this to premiumcare count too,
      // since we're showing a unified frontend
      // lct_count = subscriptionRenewCountByType().lct;
      lct_count = subscriptionRenewCountByType().premiumcare;
    }

    let premiumcare_count = 0;
    if (saleObj.premiumcare_sale) {
      premiumcare_count = saleObj.premiumcare_sale.count;
    } else if (saleDetailId === "newRenew") {
      premiumcare_count = subscriptionRenewCountByType().premiumcare;
    }

    setNewItems({
      ...pdDict,
      robot_count: saleObj.robot_count,
      lct_count: lct_count,
      premiumcare_count: premiumcare_count,
    });
    // populate potentially missing prices
    let newPricesTmp = saleObj.price_snapshot || fetchedPrices;
    const newFields = ["premiumcare"];
    newFields.forEach((el) => {
      if (!el in newPricesTmp) {
        newPricesTmp[el] = 0;
      }
    });
    setNewPrices(newPricesTmp);
  };

  const resetBasics = () => {
    // reset values to match those from saleObj
    setNewBasics({
      discount: saleObj.discount,
      adjustments: saleObj.adjustments,
      notes: saleObj.notes,
      po_number: saleObj.po_number,
    });
    setNewShippingAddress(
      saleObj.address ? saleObj.address.shipping_address : {}
    );
    setNewBillingAddress(
      saleObj.address ? saleObj.address.billing_address : {}
    );
    setSchoolOfSale(saleObj.school ? saleObj.school : null);
  };

  const getTaxRateToUse = () => {
    const taxRate =
      newPrices["tax_rate"] !== undefined
        ? newPrices["tax_rate"]
        : saleDetailOrgInfo.taxRate;
    return taxRate;
  };

  const refreshCalc = () => {
    let tmpCalcs = {};
    tmpCalcs["robot_subtotal"] =
      (newItems.robot_count || 0) *
      newPrices[`${saleDetailOrgInfo.orgType}_robot`];
    tmpCalcs["shipping_subtotal"] = roundPrices(
      (newItems.robot_count || 0) * (newPrices["shipping"] || 0)
    );

    if (saleType === "regular") {
      tmpCalcs["lct_subtotal"] =
        (lctSubscriptionLength / 12) *
        (newItems.lct_count || 0) *
        (newPrices["lct"] || 0);
      tmpCalcs["premiumcare_subtotal"] =
        (premiumCareSubscriptionLength / 12) *
        (newItems.premiumcare_count || 0) *
        (newPrices["premiumcare"] || 0);
    } else if (saleType === "renewal") {
      tmpCalcs["lct_subtotal"] =
        (lctSubscriptionLength / 12) *
        (newItems.lct_count || 0) *
        (newPrices["lct_renewal"] || 0);
      tmpCalcs["premiumcare_subtotal"] =
        (premiumCareSubscriptionLength / 12) *
        (newItems.premiumcare_count || 0) *
        (newPrices["premiumcare_renewal"] || 0);
    }

    tmpCalcs["pd1_subtotal"] =
      (newItems.pd_level_1 || 0) * newPrices["pd_level_1"];
    tmpCalcs["pd2_subtotal"] =
      (newItems.pd_level_2 || 0) * newPrices["pd_level_2"];
    tmpCalcs["pd3_subtotal"] =
      (newItems.pd_level_3 || 0) * newPrices["pd_level_3"];

    tmpCalcs["tax"] = roundPrices(
      getTaxRateToUse() * tmpCalcs["robot_subtotal"]
    );
    const tmpTotalSale =
      tmpCalcs["robot_subtotal"] +
      tmpCalcs["lct_subtotal"] +
      tmpCalcs["pd1_subtotal"] +
      tmpCalcs["pd2_subtotal"] +
      tmpCalcs["pd3_subtotal"] +
      tmpCalcs["shipping_subtotal"] +
      tmpCalcs["premiumcare_subtotal"] +
      tmpCalcs["tax"];
    tmpCalcs["total_sale"] = roundPrices(tmpTotalSale);
    setCalcs(tmpCalcs);
  };

  const renderEditableCell = (
    field,
    editing,
    valueDict,
    handleChange,
    renderDollar = true,
    defaultValue = "0",
    roundTo = null,
    renderPerc = false
  ) => {
    const displayFactor = renderPerc ? 100 : 1;
    const value =
      displayFactor > 1
        ? (valueDict[field] || defaultValue) * displayFactor
        : valueDict[field] || defaultValue;
    if (editing || isNewSale) {
      return (
        <td className='item-value'>
          {renderDollar && "$"}
          <TextField
            onWheel={(e) => {
              e.target.blur();
            }}
            size='small'
            type={roundTo ? "number" : "text"}
            value={roundValues(value, roundTo)}
            onChange={(e) => {
              handleChange(e, field, (roundTo = roundTo));
            }}
          />
          {renderPerc && "%"}
        </td>
      );
    } else {
      return (
        <td className='item-value'>
          {renderDollar && "$"}
          {roundValues(value, roundTo)}
          {renderPerc && "%"}
        </td>
      );
    }
  };

  const renderPriceCell = (field, editing) => {
    if (field === "tax_rate") {
      return renderEditableCell(
        field,
        editing,
        newPrices,
        handlePriceEdit,
        false,
        getTaxRateToUse(),
        4,
        true
      );
    } else {
      return renderEditableCell(
        field,
        editing,
        newPrices,
        handlePriceEdit,
        true,
        "0",
        2
      );
    }
  };

  const renderAddressCell = (addressType, editing, field) => {
    if (addressType === "shipping") {
      return renderEditableCell(
        field,
        editing,
        newShippingAddress,
        handleShippingAddressEdit,
        false,
        "",
        null
      );
    } else if (addressType === "billing") {
      return renderEditableCell(
        field,
        editing,
        newBillingAddress,
        handleBillingAddressEdit,
        false,
        "",
        null
      );
    }
  };

  const renderNoteCell = () => {
    // dedicated to the note cell
    if (editBasics || isNewSale) {
      return (
        <td className='item-value' colSpan='3'>
          <TextField
            style={{ width: "100%" }}
            size='small'
            multiline
            minRows={2}
            maxRows={4}
            value={newBasics["notes"] || ""}
            onChange={(e) => {
              handleBasicsEdit(e, "notes");
            }}
          />
        </td>
      );
    } else {
      return (
        <td className='item-value' colSpan='3'>
          {newBasics["notes"] || " -- "}
        </td>
      );
    }
  };

  const handlePriceEdit = (e, field) => {
    if (e.target.value < 0) {
      return;
    }
    let newPricesTmp = { ...newPrices };
    if (field === "tax_rate") {
      newPricesTmp[field] = roundValues(e.target.value / 100, 4);
    } else {
      newPricesTmp[field] = roundPrices(e.target.value);
    }
    setNewPrices(newPricesTmp);
  };

  const handleShippingAddressEdit = (e, field) => {
    let newShippingAddressTmp = { ...newShippingAddress };
    newShippingAddressTmp[field] = e.target.value;
    setNewShippingAddress(newShippingAddressTmp);
  };

  const handleBillingAddressEdit = (e, field) => {
    let newBillingAddressTmp = { ...newBillingAddress };
    newBillingAddressTmp[field] = e.target.value;
    setNewBillingAddress(newBillingAddressTmp);
  };

  const handleItemEdit = (e, field) => {
    if (e.target.value < 0) {
      return;
    }
    let newItemsTmp = { ...newItems };
    if (e.target.value !== "") {
      newItemsTmp[field] = parseInt(e.target.value);
    } else {
      newItemsTmp[field] = 0;
    }
    // let premiumcare & LCT match robot count
    if (field === "robot_count") {
      newItemsTmp["premiumcare_count"] = newItemsTmp[field];
      newItemsTmp["lct_count"] = newItemsTmp[field];
    }
    // if renewal and working with premiumcare,
    // also keep LCT in sync
    if (saleType === "renewal" && field === "premiumcare_count") {
      newItemsTmp["lct_count"] = newItemsTmp[field];
    }
    setNewItems(newItemsTmp);
  };

  const handleBasicsEdit = (e, field, roundTo = null) => {
    console.log(field);
    let newBasicsTmp = { ...newBasics };
    let newValue = e.target.value;
    if (roundTo) {
      const p = Math.pow(10, roundTo);
      newValue = Math.round(newValue * p) / p;
    }
    newBasicsTmp[field] = newValue;
    setNewBasics(newBasicsTmp);
  };

  const prepareData = () => {
    let data = {};
    data["sales_data"] = {
      sale_type: saleType,
      organization: saleDetailOrgInfo.orgId,
      school: schoolOfSale,
      robot_count: newItems.robot_count,
      tax: calcs.tax,
      total_sale_value: calcs.total_sale,
      shipping_address: {
        ...newShippingAddress,
      },
      billing_address: {
        ...newBillingAddress,
      },
      ...newBasics,
    };
    data["prices"] = { ...newPrices };
    data["subscriptions"] = [
      {
        subscription_type: "lct",
        duration_months: lctSubscriptionLength,
        max_objects: newItems.lct_count || 0,
      },
      {
        subscription_type: "premiumcare",
        duration_months: premiumCareSubscriptionLength,
        max_objects: newItems.premiumcare_count || 0,
      },
    ];
    // relevant for NEW renewals
    if (isNewSale && saleType === "renewal") {
      data["old_subscription_ids"] = renewSubscriptionIds;
    }
    data["pds"] = [
      { pd_type: "pd_level_1", count: newItems.pd_level_1 || 0 },
      { pd_type: "pd_level_2", count: newItems.pd_level_2 || 0 },
      { pd_type: "pd_level_3", count: newItems.pd_level_3 || 0 },
    ];
    return data;
  };

  const handleCreateNewSale = () => {
    // sends API request to create new sale
    console.log("Crete new Sale!");
    const postData = prepareData();
    setSpin(true);
    api
      .createSale(postData)
      .then((res) => {
        if (res.status === "success") {
          console.log("New Sale Created!");
          // sale modal set to returned SaleID
          dispatch(
            saleModalHeaderA({
              id: res.data,
              orgInfo: saleDetailOrgInfo,
            })
          );
          dispatch(refreshSaleDetailA(!refreshSaleDetail));
          dispatch(refreshSubscriptionItemsA(!refreshSubscriptionItems));
        } else {
          console.log("Something went wrong creating new sale..");
          console.log(res.data);
        }
        setSpin(false);
      })
      .catch((error) => {
        console.log("Something went wrong creating new sale..");
        console.log(error);
        setError(error);
        setSpin(false);
      });
  };

  const handleSaveChanges = (updateSection) => {
    console.log("Save Sales Changes!");
    let patchData = prepareData();
    patchData["update_type"] = updateSection;
    setSpin(true);
    api
      .editSale(saleDetailId, patchData)
      .then((res) => {
        if (res.status === "success") {
          console.log("Sale Edited!");
          dispatch(refreshSaleDetailA(!refreshSaleDetail));
          dispatch(refreshSubscriptionItemsA(!refreshSubscriptionItems));
          setEditItems(false);
          setEditBasics(false);
        } else {
          console.log("Something went wrong editing sale..");
          console.log(res.data);
        }
        setSpin(false);
      })
      .catch((error) => {
        console.log("Something went wrong editing sale..");
        console.log(error);
        setError(error);
        setSpin(false);
      });
  };

  const fillAddressWithOrgInfo = () => {
    if (orgAddress) {
      setNewShippingAddress(orgAddress);
      setNewBillingAddress(orgAddress);
    }
  };

  const refreshSchoolList = () => {
    api.fetchSuperAdminSchools(saleDetailOrgInfo.orgId).then((r) => {
      setSchoolsInOrg(r);
    });
  };

  if (!pricesReady || !subscriptionsReady) {
    return (
      <div>
        <div className='alert alert-info' role='alert'>
          Loading ...
        </div>
      </div>
    );
  } else if (error) {
    return (
      <div className='alert alert-danger' role='alert'>
        {error}
      </div>
    );
  }

  return (
    <div>
      {showCreateSchoolModal && (
        <CreateSuperAdminSchoolModal
          orgId={saleDetailOrgInfo.orgId}
          shouldShowFunc={setShowCreateSchoolModal}
          refreshList={refreshSchoolList}
        />
      )}

      <div className='row'>
        <div className='col-12'>
          <div className='mb-2 d-flex justify-content-between'>
            {saleType === "regular" ? (
              <h5>Items</h5>
            ) : (
              <h5>Subscription Renewal</h5>
            )}

            {/* Make it only editable before sale finalized? */}
            {editItems || isNewSale ? (
              <div>
                <button
                  className='btn btn-sm btn-primary'
                  disabled={(isNewSale && calcs.total_sale === 0) || spin}
                  onClick={() => {
                    // IMPORTANT: THIS BUTTON doubles as the master submit button
                    // when we are initiating new sales
                    if (isNewSale) {
                      handleCreateNewSale();
                    } else {
                      handleSaveChanges("sale_items");
                    }
                  }}
                >
                  {spin ? (
                    <FA icon='spinner' color='white' spin />
                  ) : (
                    <FA icon='save' color='white' />
                  )}
                  {isNewSale ? "Create This Sale" : "Save Changes"}
                </button>
                {isNewSale === false && (
                  <button
                    className='btn btn-sm btn-danger ml-2'
                    onClick={() => {
                      setEditItems(false);
                      resetItems();
                    }}
                  >
                    Cancel
                  </button>
                )}
              </div>
            ) : (
              <button
                className='btn btn-sm btn-primary'
                disabled={saleObj.can_edit_items === false}
                onClick={() => {
                  setEditItems(true);
                }}
              >
                Edit
              </button>
            )}
          </div>

          {saleType === "regular" && (
            <table className='table table-striped table-sm'>
              <thead>
                <tr>
                  <th></th>
                  <th>#</th>
                  <th>Unit Price / Annual Rate</th>
                  <th>Note</th>
                  <th>Subtotal</th>
                </tr>
              </thead>
              <tbody className='sales_detail_table_tbody'>
                <tr>
                  <td className='item-label'>Robots</td>
                  {renderEditableCell(
                    "robot_count",
                    editItems,
                    newItems,
                    handleItemEdit,
                    false
                  )}
                  {renderPriceCell(
                    `${saleDetailOrgInfo.orgType}_robot`,
                    editItems
                  )}
                  <td style={{ textTransform: "capitalize" }}>
                    {saleDetailOrgInfo.orgType}
                  </td>
                  <td className='item-label'>${calcs.robot_subtotal}</td>
                </tr>
                {saleReturns.length > 0 && (
                  <tr className='alert alert-warning'>
                    <td className='item-label'>Returned Robots</td>
                    <td>{returnData.robot_count}</td>
                    <td></td>
                    <td></td>
                    <td className='item-label'>
                      - ${returnData.refund_amount}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className='item-label'>LCTs</td>
                  {renderEditableCell(
                    "lct_count",
                    editItems,
                    newItems,
                    handleItemEdit,
                    false
                  )}
                  {renderPriceCell("lct", editItems)}
                  {editItems || isNewSale ? (
                    <td colSpan='1'>
                      <TextField
                        select
                        className='w-100'
                        label=''
                        value={lctSubscriptionLength}
                        onChange={(e) => {
                          setLctSubscriptionLength(e.target.value);
                        }}
                      >
                        {Object.keys(subscriptionLengthChoices).map(
                          (text_key) => (
                            <MenuItem
                              key={text_key}
                              value={subscriptionLengthChoices[text_key]}
                            >
                              {text_key}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </td>
                  ) : (
                    <td colSpan='1'>{monthToChoices[lctSubscriptionLength]}</td>
                  )}
                  <td className='item-label'>${calcs.lct_subtotal}</td>
                </tr>
                <tr>
                  <td className='item-label'>Abii Premium Care</td>
                  {renderEditableCell(
                    "premiumcare_count",
                    editItems,
                    newItems,
                    handleItemEdit,
                    false
                  )}
                  {renderPriceCell("premiumcare", editItems)}
                  {editItems || isNewSale ? (
                    <td colSpan='1'>
                      <TextField
                        select
                        className='w-100'
                        label=''
                        value={premiumCareSubscriptionLength}
                        onChange={(e) => {
                          setPremiumCareSubscriptionLength(e.target.value);
                        }}
                      >
                        {Object.keys(subscriptionLengthChoices).map(
                          (text_key) => (
                            <MenuItem
                              key={text_key}
                              value={subscriptionLengthChoices[text_key]}
                            >
                              {text_key}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </td>
                  ) : (
                    <td colSpan='1'>
                      {monthToChoices[premiumCareSubscriptionLength]}
                    </td>
                  )}
                  <td className='item-label'>${calcs.premiumcare_subtotal}</td>
                </tr>
                <tr>
                  <td className='item-label'>PD (level 1)</td>
                  {renderEditableCell(
                    "pd_level_1",
                    editItems,
                    newItems,
                    handleItemEdit,
                    false
                  )}
                  {renderPriceCell("pd_level_1", editItems)}
                  <td></td>
                  <td className='item-label'>${calcs.pd1_subtotal}</td>
                </tr>
                <tr>
                  <td className='item-label'>PD (level 2)</td>
                  {renderEditableCell(
                    "pd_level_2",
                    editItems,
                    newItems,
                    handleItemEdit,
                    false
                  )}
                  {renderPriceCell("pd_level_2", editItems)}
                  <td></td>
                  <td className='item-label'>${calcs.pd2_subtotal}</td>
                </tr>
                <tr>
                  <td className='item-label'>ACE Coaching</td>
                  {renderEditableCell(
                    "pd_level_3",
                    editItems,
                    newItems,
                    handleItemEdit,
                    false
                  )}
                  {renderPriceCell("pd_level_3", editItems)}
                  <td></td>
                  <td className='item-label'>${calcs.pd3_subtotal}</td>
                </tr>
                <tr>
                  <td className='item-label'>Shipping</td>
                  <td></td>
                  {renderPriceCell("shipping", editItems)}
                  <td></td>
                  <td className='item-label'>${calcs.shipping_subtotal}</td>
                </tr>
                <tr>
                  <td className='item-label'>Tax</td>
                  <td>
                    <span style={{ display: "none" }}>{getTaxRateToUse()}</span>
                  </td>
                  {renderPriceCell("tax_rate", editItems)}
                  <td>(Robots)</td>
                  <td className='item-label'>${calcs.tax}</td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span style={{ display: "none" }}>
                      sanity check:{" "}
                      {calcs.total_sale - (returnData.refund_amount || 0)}
                    </span>
                  </td>
                  <td className='item-label'>Total Sale</td>
                  {saleReturns.length > 0 ? (
                    <td className='item-label'>
                      ${roundPrices(saleObj.sale_value_after_returns)}
                    </td>
                  ) : (
                    <td className='item-label'>${calcs.total_sale}</td>
                  )}
                </tr>
              </tbody>
            </table>
          )}

          {saleType === "renewal" && (
            <div>
              <SubscriptionRenewalRows subscriptions={renewSubscriptionInfo} />

              {renderSubscriptionCountWarningMessages("premiumcare")}
              {renderSubscriptionCountWarningMessages("lct")}

              <table className='table table-striped table-sm mt-3'>
                <thead>
                  <tr>
                    <th>Renewal Items</th>
                    <th>#</th>
                    <th>Annual Rate</th>
                    <th># of Years</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody className='sales_detail_table_tbody'>
                  <tr>
                    <td className='item-label'>Abii Premium Care (&LCT)</td>
                    {renderEditableCell(
                      "premiumcare_count",
                      editItems,
                      newItems,
                      handleItemEdit,
                      false
                    )}
                    {renderPriceCell("premiumcare_renewal", editItems)}
                    {editItems || isNewSale ? (
                      <td colSpan='1'>
                        <TextField
                          select
                          className='w-100'
                          label=''
                          value={premiumCareSubscriptionLength}
                          onChange={(e) => {
                            setPremiumCareSubscriptionLength(e.target.value);
                            setLctSubscriptionLength(e.target.value);
                          }}
                        >
                          {Object.keys(subscriptionLengthChoices).map(
                            (text_key) => (
                              <MenuItem
                                key={text_key}
                                value={subscriptionLengthChoices[text_key]}
                              >
                                {text_key}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                      </td>
                    ) : (
                      <td colSpan='1'>
                        {monthToChoices[premiumCareSubscriptionLength]}
                      </td>
                    )}
                    <td className='item-label'>
                      ${calcs.premiumcare_subtotal + calcs.lct_subtotal}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan='3'></td>
                    <td className='item-label'>Total Sale</td>
                    <td className='item-label'>${calcs.total_sale}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      <div className='row'>
        <div className='col-12 table-responsive'>
          <div className='mb-2 d-flex justify-content-between'>
            <h5>Sale Info</h5>
            {/* Make it only editable before sale finalized? */}
            {isNewSale === false && editBasics && (
              <div>
                <button
                  className='btn btn-sm btn-primary'
                  disabled={spin}
                  onClick={() => {
                    handleSaveChanges("sale_info");
                  }}
                >
                  {spin ? (
                    <FA icon='spinner' color='white' spin />
                  ) : (
                    <FA icon='save' color='white' />
                  )}{" "}
                  Save Changes
                </button>
                <button
                  className='btn btn-sm btn-danger ml-2'
                  onClick={() => {
                    setEditBasics(false);
                    resetBasics();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}

            {isNewSale === false && !editBasics && (
              <button
                className='btn btn-sm btn-primary'
                onClick={() => {
                  setEditBasics(true);
                }}
              >
                Edit
              </button>
            )}
          </div>
          <table className='table table-striped table-sm'>
            <tbody className='sales_detail_table_tbody'>
              {isNewSale === false && (
                <React.Fragment>
                  <tr>
                    <td className='item-label'>Sales Status</td>
                    <td className='item-value'>{saleObj.status_display}</td>
                    <td className='item-label'>Status Updated</td>
                    <td className='item-value'>
                      {moment
                        .utc(saleObj.last_status_changed_on)
                        .local()
                        .format("MMMM Do YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <td className='item-label'>Sales ID</td>
                    <td className='item-value'>{saleObj.id}</td>
                    <td className='item-label'>Sales Creation Date</td>
                    <td className='item-value'>
                      {moment
                        .utc(saleObj.created_at)
                        .local()
                        .format("MMMM Do YYYY")}
                    </td>
                  </tr>
                </React.Fragment>
              )}
              <tr>
                <td className='item-label'>PO # (optional)</td>
                {renderEditableCell(
                  "po_number",
                  editBasics,
                  newBasics,
                  handleBasicsEdit,
                  false,
                  ""
                )}
                <td colSpan='2'></td>
              </tr>
              <tr>
                <td style={{ fontSize: "large" }} className='item-label'>
                  Sale Notes
                </td>
                {renderNoteCell()}
              </tr>
            </tbody>
          </table>

          {saleDetailOrgInfo.orgLevel === config.ORG_LEVEL_DISTRICT && (
            <div className='my-4'>
              <div className='d-flex'>
                <h5>Buyer</h5>
                {(isNewSale === true || editBasics) && (
                  <span
                    className='like-link ml-3'
                    onClick={() => setShowCreateSchoolModal(true)}
                  >
                    Create New Buyer Label
                  </span>
                )}
              </div>
              <div className='col-6'>
                {isNewSale === true || editBasics ? (
                  <select
                    className='form-control'
                    value={schoolOfSale}
                    onChange={(e) => setSchoolOfSale(e.target.value)}
                  >
                    <option value=''>District Level</option>
                    {schoolsInOrg.map((school) => (
                      <option value={school.id}>{school.name}</option>
                    ))}
                  </select>
                ) : (
                  <p style={{ fontWeight: "bold" }}>
                    {saleObj.school_display || "District Level"}
                  </p>
                )}
              </div>
            </div>
          )}

          <div className='mb-2 d-flex justify-content-between'>
            <h5>School-Specific Info</h5>
            {(isNewSale === true || editBasics) && (
              <button
                disabled={orgAddress === null}
                className='btn btn-sm btn-primary'
                onClick={() => {
                  fillAddressWithOrgInfo();
                }}
              >
                Fill With Organization Address
              </button>
            )}
          </div>

          <table className='table table-striped table-sm'>
            <tbody className='sales_detail_table_tbody'>
              <tr>
                <td colSpan='4' className='h5'>
                  Shipping Address
                </td>
              </tr>
              <tr>
                <td className='item-label'>Contact Name</td>
                {renderAddressCell("shipping", editBasics, "contact_name")}
              </tr>
              <tr>
                <td className='item-label'>Phone</td>
                {renderAddressCell("shipping", editBasics, "phone")}
                <td className='item-label'>Email</td>
                {renderAddressCell("shipping", editBasics, "email")}
              </tr>
              <tr>
                <td className='item-label'>Address Line 1</td>
                {renderAddressCell("shipping", editBasics, "address1")}
                <td className='item-label'>Address Line 2</td>
                {renderAddressCell("shipping", editBasics, "address2")}
              </tr>
              <tr>
                <td className='item-label'>City</td>
                {renderAddressCell("shipping", editBasics, "city")}
                <td className='item-label'>State</td>
                {renderAddressCell("shipping", editBasics, "state")}
              </tr>
              <tr>
                <td className='item-label'>Zipcode</td>
                {renderAddressCell("shipping", editBasics, "zipcode")}
                <td className='item-label'>Country</td>
                {renderAddressCell("shipping", editBasics, "country")}
              </tr>

              <tr>
                <td colSpan='4' className='h5'>
                  Billing Address
                </td>
              </tr>
              <tr>
                <td className='item-label'>Contact Name</td>
                {renderAddressCell("billing", editBasics, "contact_name")}
              </tr>
              <tr>
                <td className='item-label'>Phone</td>
                {renderAddressCell("billing", editBasics, "phone")}
                <td className='item-label'>Email</td>
                {renderAddressCell("billing", editBasics, "email")}
              </tr>
              <tr>
                <td className='item-label'>Address Line 1</td>
                {renderAddressCell("billing", editBasics, "address1")}
                <td className='item-label'>Address Line 2</td>
                {renderAddressCell("billing", editBasics, "address2")}
              </tr>
              <tr>
                <td className='item-label'>City</td>
                {renderAddressCell("billing", editBasics, "city")}
                <td className='item-label'>State</td>
                {renderAddressCell("billing", editBasics, "state")}
              </tr>
              <tr>
                <td className='item-label'>Zipcode</td>
                {renderAddressCell("billing", editBasics, "zipcode")}
                <td className='item-label'>Country</td>
                {renderAddressCell("billing", editBasics, "country")}
              </tr>
            </tbody>
          </table>

          {isNewSale === false && editBasics && (
            <div className='float-right'>
              <button
                className='btn btn-sm btn-primary'
                disabled={spin}
                onClick={() => {
                  handleSaveChanges("sale_info");
                }}
              >
                {spin ? (
                  <FA icon='spinner' color='white' spin />
                ) : (
                  <FA icon='save' color='white' />
                )}{" "}
                Save Changes
              </button>
              <button
                className='btn btn-sm btn-danger ml-2'
                onClick={() => {
                  setEditBasics(false);
                  resetBasics();
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withAPI
)(SalesInfoDetailTab);
