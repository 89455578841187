import Checkbox from "@material-ui/core/Checkbox";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import FA from "../../containers/fa";
import withAPI from "../../services/api";
import PaginationBar from "../UtilComps/PaginationBar.js";
import SubscriptionRow from "./SubscriptionRow";

const mapStateToProps = (state, ownProps?) => ({
  user: state.auth.user,
  refreshSubscriptionItems: state.superAdmin.refreshSubscriptionItems,
});

// This is only available to Super Admins
const AllSubscriptionsTable = ({ api, refreshSubscriptionItems }) => {
  // Fetch subscriptions either by org or sale
  const [ready, setReady] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [recentExpires, setRecentExpires] = useState(true);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [spin, setSpin] = useState(true);
  const [error, setError] = useState(null);
  const [showSAUpdateReminder, setShowSAUpdateReminder] = useState(false);

  useEffect(() => {
    refreshSubscriptionList();
  }, [refreshSubscriptionItems, pageSize]);

  const buildFilterQueryParams = () => {
    const params = {
      recent_expires: recentExpires,
      order_by: recentExpires ? "end_date" : null,
    };
    return params;
  };

  const refreshSubscriptionList = (pageNum) => {
    setSpin(true);
    let queryParams = buildFilterQueryParams();
    queryParams.page = pageNum;
    queryParams.page_size = pageSize;

    // skip nulls,  empty strings, false values
    queryParams = Object.entries(queryParams).filter(([k, v]) => v);
    api
      .fetchSubscriptions(queryParams)
      .then((res) => {
        const subscriptions = res.results;
        const subsAfterRemovingProcessedRenewals = subscriptions.filter(
          (sub) => sub.processed_renewal == null
        );
        setSubscriptions(subsAfterRemovingProcessedRenewals);
        delete res["results"];
        delete res["stats"];
        setPaginationInfo(res);
        setReady(true);
        setSpin(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setSpin(false);
      });
  };

  return (
    <div className='common_border'>
      <div className='common_heading d-flex justify-content-between align-items-center'>
        <span>
          <h4>Subscriptions</h4>
        </span>
      </div>

      <div className='common_dashboard_bg'>
        <div className='row'>
          <div className='col-12'>
            <div style={{ marginBottom: "5px" }}>
              <button
                className='btn btn-primary mb-1'
                onClick={(e) => {
                  api.exportSubscriptions();
                }}
              >
                Export Subscriptions
              </button>

              <div>
                <div className='table-filter-wrapper p-3'>
                  <h5>Filters:</h5>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "stretch",
                      flexFlow: "wrap",
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <div
                        style={{ display: "inline-flex", alignItems: "center" }}
                      >
                        <Checkbox
                          color='primary'
                          checked={recentExpires}
                          onChange={(e) => {
                            setRecentExpires(!recentExpires);
                          }}
                        />
                        <p style={{ margin: "0px 10px 0px 0px" }}>
                          Recent Expires
                        </p>
                      </div>
                    </div>
                    <button
                      className='btn btn-primary btn-sm ml-2'
                      onClick={() => {
                        refreshSubscriptionList(null);
                      }}
                    >
                      Update Search
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-10'></div>
            {error && (
              <div className='alert alert-danger' role='alert'>
                {error}
              </div>
            )}
            {spin && !ready && (
              <div>
                <h4>
                  <FA color='gray' icon='spinner' spin /> Loading
                  Subscriptions...
                </h4>
              </div>
            )}

            <PaginationBar
              paginationInfo={paginationInfo}
              fetchPageFunc={refreshSubscriptionList}
              loading={spin}
              setPageSize={setPageSize}
              pageSize={pageSize}
            />

            {ready && (
              <div className='card'>
                <div
                  id='subscription_row_table_all'
                  className='visible-scrollbar'
                  role='subscriptions'
                >
                  <div className='subscription_row_hr_all'>
                    <div>Organization</div>
                    <div>Subscription Type</div>
                    <div>Sale Id</div>
                    <div>Status</div>
                    <div>Expiration Date</div>
                    <div>Total Slots</div>
                    <div>Used</div>
                    <div>Actions</div>
                  </div>

                  {/* need to also update this component to show renewal without robots icon */}
                  {ready && subscriptions && subscriptions.length > 0 ? (
                    subscriptions.map((sub) => (
                      <SubscriptionRow
                        key={sub.id}
                        subscription={sub}
                        refreshSubscriptionList={refreshSubscriptionList}
                        isSuperAdmin={true}
                        fetchAll={true}
                        extraFields={["utilized_count"]}
                        setShowSAUpdateReminder={setShowSAUpdateReminder}
                        saleIsFinalizedState={true} // It shouldn't show up on this page if no invoice is sent/paid
                        showRenewalWithoutRobotsIcon={
                          sub.is_renewal_without_robots
                        }
                      />
                    ))
                  ) : (
                    <div style={{ textAlign: "center", padding: "10px" }}>
                      <FA icon='spinner' spin /> Loading ...
                    </div>
                  )}
                </div>
              </div>
            )}

            <PaginationBar
              paginationInfo={paginationInfo}
              fetchPageFunc={refreshSubscriptionList}
              loading={spin}
              pageSize={pageSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(mapStateToProps),
  withAPI
)(AllSubscriptionsTable);
