import React, { useState, useEffect } from "react";
import "./LessonContextTextInfo.scss";

const LessonContextTextInfo = ({
  lessonContextInfo,
  pairings,
  updateLessonData,
  consolidatedReports = false,
}) => {
  const [overallQuestionHtml, setOverallQuestionHtml] = useState("");
  const [showHideState, setShowHideState] = useState(null);

  const showFullQuestion = () => {
    console.log("showFullQuestion");
    let overallQ = lessonContextInfo.overall_question;
    setOverallQuestionHtml(overallQ);
    setShowHideState("show");
  };

  const hideFullQuestion = () => {
    let overallQ = lessonContextInfo.overall_question;
    overallQ = overallQ.substring(0, 100) + "... ";
    setShowHideState("hide");
    setOverallQuestionHtml(overallQ);
  };

  useEffect(() => {
    let overallQ = lessonContextInfo.overall_question;
    let tempState = null;
    if (overallQ && overallQ.length > 100) {
      overallQ = overallQ.substring(0, 100) + "... ";
      tempState = "hide";
    }
    setShowHideState(tempState);
    setOverallQuestionHtml(overallQ);
  }, [lessonContextInfo]);

  return (
    <div className='row'>
      <div className='col-12'>
        <h5 className='font-weight-bold mb-2'>Lesson Info</h5>
        <table className='table borderless text-left table-sm'>
          <colgroup>
            <col span='1' style={{ width: "30%" }} />
            <col span='1' style={{ width: "70%" }} />
          </colgroup>

          <tbody>
            <tr className='value-row'>
              <td>Course: </td>
              <td>{lessonContextInfo.course_name}</td>
            </tr>
            <tr className='value-row'>
              <td>Unit: </td>
              <td>{lessonContextInfo.unit}</td>
            </tr>
            <tr className='value-row'>
              <td>Standard: </td>
              <td>{lessonContextInfo.standard}</td>
            </tr>
            <tr className='value-row'>
              <td>Lesson Type: </td>
              <td>
                {lessonContextInfo.type === "TUT" ? "Tutorial" : "Assessment"}
              </td>
            </tr>
            {pairings[lessonContextInfo.label_lesson_id] !== null && (
              <tr className='value-row'>
                <td>Associated ASM: </td>
                <td>
                  <span
                    className='like-link'
                    onClick={() => {
                      if (consolidatedReports) {
                        updateLessonData(
                          pairings[lessonContextInfo.label_lesson_id].id,
                          pairings[lessonContextInfo.label_lesson_id],
                          true
                        );
                        return;
                      }
                      updateLessonData(
                        pairings[lessonContextInfo.label_lesson_id].id
                      );
                    }}
                  >
                    {pairings[lessonContextInfo.label_lesson_id] &&
                      pairings[lessonContextInfo.label_lesson_id].name}
                  </span>
                </td>
              </tr>
            )}
            {lessonContextInfo.type === "TUT" && (
              <tr className='value-row'>
                <td>Overall Question: </td>
                <td>
                  <p
                    className='mb-0'
                    dangerouslySetInnerHTML={{
                      __html: overallQuestionHtml || "N/A",
                    }}
                  ></p>
                  {showHideState === "hide" ? (
                    <span
                      className='like-link'
                      onClick={() => showFullQuestion()}
                    >
                      Show More
                    </span>
                  ) : null}
                  {showHideState === "show" ? (
                    <span
                      className='like-link'
                      onClick={() => hideFullQuestion()}
                    >
                      Show Less
                    </span>
                  ) : null}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LessonContextTextInfo;
